import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "../message";
import AudiencesService from "../../../services/audiences";

export const getAudiences = createAsyncThunk(
  "campagins/getAudiences",
  async ({page, filterData, isAll}, thunkAPI) => {
    try {
      console.log(
        "get audience all thunk calling....",
        page,
        filterData,
        isAll
      );
      const result = await AudiencesService.getAudiences(
        page,
        filterData,
        isAll
      );
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {audiences: null};

const audiencesSlice = createSlice({
  name: "audiences",
  initialState,

  extraReducers: {
    [getAudiences.fulfilled]: (state, action) => {
      console.log("audiences.fulfilled...", action.payload);
      state.audiences = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getAudiences.rejected]: (state, action) => {
      state.audiences = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default audiencesSlice.reducer;
