import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getUsers = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();
    
    data = {page, filterData };
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_USERS, data, token);
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const createUser = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    let result = await apiService.post(API_URL.USER_CREATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getUser = async (id) => {
  try {
    
    let token = getToken();

    let result = await apiService.get(API_URL.GET_USER+id, token);
    return result;

  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const updateUser = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log('Service request payload...data', data)
    let result = await apiService.put(API_URL.USER_UPDATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const deleteUser = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    
    let result = await apiService.post(API_URL.USER_DELETE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const usersService = {
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser
};

export default usersService;
