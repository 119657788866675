import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Button} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import {createCampaignTag, updateCampaignTag} from "../../store/slices/campaignTags/create"

const NewCampaignTag = (props) =>{
  const {campaignTag} = props
    const dispatch = useDispatch()

    const tagSchema = Yup.object().shape({
        name:Yup.string().required('Tag Name is required field'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: campaignTag?.name ? campaignTag?.name : '',
        },
        validationSchema:tagSchema,

        onSubmit: (values, {resetForm}) => {
            console.log(values);
            
            if(campaignTag?.id){
              values.id = campaignTag.id
              dispatch(updateCampaignTag(values));
            }else{
              dispatch(createCampaignTag(values));
            }
            
            props.onNewTemplateSubmit()
            resetForm({values:''})
        },
   
      });

      
    return(
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Tag Name</label>
                <div className="col-md-9">
                    <input type="text" className="form-control" 
                    id="name" 
                    name="name"
                    onChange={formik.handleChange} 
                    value={formik.values.name} 
                    />
                    {formik.touched.name &&
              formik.errors.name ? (
                <span className="error text-danger">
                  {formik.errors.name}
                </span>
              ) : null}
                </div>
                
            </div>
            
            <div id="btnWrapper" className='text-center mt-4'>
                <Button variant='primary' type="submit">Submit</Button>
            </div>
        </form>
    )
}

export default NewCampaignTag;