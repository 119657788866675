import Classes from "./style.module.css";
import {Button} from "react-bootstrap";
const CampaignFooter = (props) => {
  const {nextStep, buttonTitle, onNextStepHandler, showDraft} = props;

  const _nextStepProcess = () => {
    try {
      onNextStepHandler();
    } catch (error) {
      console.log("next step button click error");
    }
  };
  const _saveInDraftProcess = () => {
    try {
      onNextStepHandler("draft");
    } catch (error) {
      console.log("next step button click error");
    }
  };
  return (
    <div className={Classes.footerWrapper}>
      {showDraft ? (
        <div>
          <Button
            className="btn-lg"
            variant="secondary"
            onClick={_saveInDraftProcess}
          >
            Save in Draft
          </Button>
        </div>
      ) : (
        <div>Next: {nextStep}</div>
      )}
      <div>
        <Button className="btn-lg" onClick={_nextStepProcess}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};

export default CampaignFooter;
