import {Fragment, useEffect, useState} from 'react';
import {Col, Button,Row} from "react-bootstrap"
import {Link, useParams,useNavigate} from "react-router-dom"
import Select from 'react-select';
import { useSelector } from 'react-redux';
import TemplatePreview from "./TemplatePreview"

const TemplateSelector = (props) =>{
    const {templates, 
        selectedTemplate, 
        setSelectedTemplate, 
        campaign, 
        templateFor,
        setRefreshStep
    } = props
    const [options, setOptions] = useState([{label:'Select Template', value:''}])
    const [showTestPop, setShowTestPop] = useState(false)
    const [isValid, setIsValid] = useState(true)
    let params = useParams()
    let navigate = useNavigate()
    const newCreatedTemplate = useSelector((state)=>state.createTemplateSlice.template)

    const _templateChangeHandle = (event) =>{
        console.log('template - ', event)
        setSelectedTemplate(event)
    }
    console.log('template selecter........loaded template ########', templates)
    const getTemplateDropDownOptions =  (options) =>{
        let optionsList = []
       
        for(let i=0; i<options.length; i++){
            optionsList.push({...options[i],label:options[i].name, value:options[i].id})
        }
        return optionsList
    }

    useEffect(()=>{
        if(newCreatedTemplate && newCreatedTemplate?.data?.id>0){
            let selectedOption = {
                label:newCreatedTemplate.data.name,
                value:newCreatedTemplate.data.id, 
                ...newCreatedTemplate?.data 
            }
            
            setSelectedTemplate(selectedOption)
            setRefreshStep(true)
        }
    },[newCreatedTemplate])
    
    useEffect(()=>{
        if(campaign?.data?.id>0 && selectedTemplate?.value==='' && templates?.data?.length>0){
           let optionsList = getTemplateDropDownOptions(templates?.data)
           if(campaign?.data?.template?.id){
            let currentTemplate = optionsList.find((item)=>item.id===campaign?.data?.template?.id)
            
            setSelectedTemplate(currentTemplate)
           }
        }
        
    },[campaign,templates,selectedTemplate, setSelectedTemplate])

    useEffect(()=>{
        if(templates && templates.data.length>0){
            let optionsList = getTemplateDropDownOptions(templates.data)
            setOptions(()=>(optionsList))
        }else{
            setOptions(()=>([{value:'',label:'Select Template'}]))
        }
        
    },[templates])

    const _closePopHandle = () =>{
        try{
            setShowTestPop(false)
        }catch(error){
            console.log(error)
        }
    }

    const _previewHandler = () =>{
        try{
            if(!selectedTemplate.value){
                setIsValid(false)
                return 
            }
            
            setShowTestPop(true) 
        }catch(error){
            console.log(error)
        }
    }
    const _editTemplateHandler = () =>{
        try{
            
            navigate("/campaigns/edit/update-template/"+params.id+"/"+selectedTemplate?.value)
        
        }catch(error){
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Row>
                <Col sm={9}>
                <Select
                    value={selectedTemplate}
                    onChange={_templateChangeHandle}
                    options={options}
                    />
                </Col>
                <Col sm={3}><Button variant='secondary' onClick={_previewHandler}>Preview</Button> {selectedTemplate?.value && <Button variant='secondary' onClick={_editTemplateHandler}>Edit</Button>}</Col>
                
            </Row>
           {!selectedTemplate?.value && !isValid && <Row><Col className='alert-danger mt-3 p-2'>Please select a Template</Col></Row>}

            <Row className='my-5'>
                <Col>
                    <h3 className='text-center'>OR</h3>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Link className='btn btn-primary' to={"/campaigns/edit/createtemplate/"+params.id}>Add New Template for {templateFor}</Link>
                </Col>
            </Row>
            <TemplatePreview 
            selectedTemplate={selectedTemplate} 
            campaign={campaign} 
            showTestPop={showTestPop} 
            closeTestPop={_closePopHandle} />
            
        </Fragment>
        
      );
}

export default TemplateSelector;