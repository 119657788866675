import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Card, ListGroup, Row, Col, Button} from "react-bootstrap";
import moment from "moment";
import Classes from "./style.module.css";
import {useParams, useLocation} from "react-router-dom";
import {updateCampaign} from "../../store/slices/campaigns/createCampaign";
import {useDispatch} from "react-redux";
import CampaignsTest from "../Campaigns/CampaignsTest";

const ProgressBar = (props) => {
  const {updateCampData, campaign, campaignRecurrence, allchannels} = props;
  const [channel, setChannel] = useState("...");
  const [isPublished, setIsPublished] = useState(false);
  const [campaignName, setCampaignName] = useState("...");
  const [selectedAudience, setSelectedAudience] = useState("...");
  const [selectedTemplate, setSelectedTemplate] = useState("...");
  const [show, setShow] = useState(false);
  //const [others, setOthers] = useState('')
  const params = useParams();
  const navigate = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let campaignName = "...";
    let selectedAudienceLabel = "...";
    let selectedTemplate = "...";
    let slectedChannel = updateCampData?.channel?.name
      ? updateCampData?.channel?.name
      : "";

    if (!slectedChannel) {
      if (allchannels?.data && updateCampData?.channel) {
        let channel = allchannels?.data.find((item) => {
          return item.id === updateCampData?.channel;
        });

        if (channel?.name) {
          slectedChannel = channel.name;
        }
      }
    }

    console.log("updated channel ....", slectedChannel);

    if (updateCampData?.id > 0) {
      if (!slectedChannel) {
        slectedChannel = updateCampData?.channel?.name
          ? updateCampData?.channel.name
          : "";
      }

      campaignName = updateCampData?.name;
      selectedAudienceLabel = updateCampData?.audience?.name
        ? updateCampData?.audience?.name
        : "...";
      selectedTemplate = updateCampData?.template?.name
        ? updateCampData?.template?.name
        : "...";
      console.log(
        "selected audience.....$$ updated",
        selectedAudienceLabel,
        updateCampData
      );
      console.log("selected template.....$$ updated side", selectedTemplate);
      setCampaignName(campaignName);
      setSelectedAudience(selectedAudienceLabel);
      setSelectedTemplate(selectedTemplate);
    } else {
      //if(campaign?.data?.id>0){
      if (!slectedChannel) {
        slectedChannel = campaign?.data?.channel?.name
          ? campaign?.data?.channel.name
          : "";
      }

      campaignName = campaign?.data?.name;
      selectedAudienceLabel = campaign?.data?.audience?.name
        ? campaign?.data?.audience?.name
        : "...";
      selectedTemplate = campaign?.data?.template?.name
        ? campaign?.data?.template?.name
        : "...";
      console.log(
        "selected audience.....$$ campaign",
        selectedAudienceLabel,
        campaign
      );
      setCampaignName(campaignName);
      setSelectedAudience(selectedAudienceLabel);
      setSelectedTemplate(selectedTemplate);
      setIsPublished(campaign?.data?.status === "published" ? true : false);
      // }
    }

    setChannel(slectedChannel ? slectedChannel : "...");
    //setStatus(status)
    console.log("campaign sidebar details....", campaign);
  }, [campaign, updateCampData, allchannels]);
  const status = updateCampData?.id
    ? updateCampData.status
    : campaign?.data?.id > 0
    ? campaign?.data?.status
    : "draft";

  const getScheduledInfo = () => {
    if (!campaignRecurrence?.repeatEvery) {
      return "...";
    }
    console.log("recurrence data of campaign ..", campaignRecurrence);
    return (
      <>
        {campaignRecurrence?.repeatEvery && (
          <p className="pt-2 mb-0">
            {moment(campaign?.data?.scheduledDateTime).format("YYYY-MM-DD")}
          </p>
        )}
        {campaignRecurrence?.repeatEvery && (
          <p className="py-2 mb-0">
            Repeat Every:{" "}
            <label className="inline-block pr-3">
              {campaignRecurrence?.repeatEvery}{" "}
              {campaignRecurrence?.durationType}
            </label>{" "}
          </p>
        )}
        {campaignRecurrence?.repeatEvery && (
          <p className="py-2 mb-0">
            Ends On:{" "}
            <label className="inline-block pr-3">
              {campaignRecurrence?.endsOn
                ? moment(campaignRecurrence?.endsOn).format("YYYY-MM-DD")
                : "Never"}
            </label>{" "}
          </p>
        )}
      </>
    );
  };

  const getOthers = () => {
    if (!campaign?.data?.toData) {
      return "...";
    }

    return (
      <>
        {campaign?.data?.toData && (
          <p className="pt-2 mb-0">To: {campaign?.data?.toData}</p>
        )}
        {campaign?.data?.ccData && (
          <p className="pt-2 mb-0">CC: {campaign?.data?.ccData}</p>
        )}
      </>
    );
  };

  const getActiveTab = (key, tabName, tabValue) => {
    const campaignSteps = navigate.pathname.split("/");
    let classList = "";
    if (key === "new" && tabName === "channel") {
      if (campaignSteps.includes(key)) {
        classList = Classes.active;
      } else {
        if (!tabValue || tabValue === "...") {
          classList = Classes.disable;
        }
      }
    } else {
      if (campaignSteps.includes(key)) {
        classList = Classes.active;
      } else {
        if (!tabValue || tabValue === "...") {
          classList = Classes.disable;
        }
      }
    }
    return classList;
  };

  const _getTabLink = (tabTitle, content, tabUrl) => {
    try {
      let campaignId = params.id;
      return (
        <>
          {content === "..." ? (
            <span>
              <label>{tabTitle}</label> {content}
            </span>
          ) : (
            <Link to={`${tabUrl}/${campaignId}`}>
              <span>
                <label>{tabTitle}</label> {content}
              </span>
            </Link>
          )}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const _popUpHandler = (isShow) => {
    setShow(isShow);
  };

  const __statusChangeHandler = (id, event) => {
    try {
      let status = event.target.checked ? "published" : "draft";
      setIsPublished(event.target.checked);

      let payload = {id, status};
      console.log("payload value###### ", payload);
      dispatch(updateCampaign(payload));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <ListGroup variant="flush" className={Classes.progressBar}>
        <ListGroup.Item className={Classes.listheading}>
          <Row>
            <Col sm={8}>
              <label>Status: {status}</label>
            </Col>
            {campaign?.data?.status && (
              <Col sm={4}>
                {campaign?.data?.id && (
                  <span
                    className="form-check form-switch"
                    style={{padding: "0px", paddingTop: "3px"}}
                  >
                    <input
                      className="form-check-input"
                      style={{height: "1.5rem", width: "3rem", float: "right"}}
                      onChange={(e) => {
                        __statusChangeHandler(campaign?.data?.id, e);
                      }}
                      type="checkbox"
                      checked={isPublished}
                      role="switch"
                      id="campaignstatuschange"
                    />
                    {isPublished}
                  </span>
                )}
              </Col>
            )}
          </Row>
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("new", "channel", channel) + " p-0"}
        >
          <span>
            <label>Channel:</label> {channel}
          </span>
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("campaign", "", campaignName) + " p-0"}
        >
          {_getTabLink("Campaign:", campaignName, "/campaigns/edit/campaign")}
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("audience", "", selectedAudience) + " p-0"}
        >
          {_getTabLink(
            "Select Audience:",
            selectedAudience,
            "/campaigns/edit/audience"
          )}
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("others", "Others", getOthers()) + " p-0"}
        >
          {_getTabLink("Others", getOthers(), "/campaigns/edit/others")}
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("template", "", selectedTemplate) + " p-0"}
        >
          {_getTabLink(
            "Select Template:",
            selectedTemplate,
            "/campaigns/edit/template"
          )}
        </ListGroup.Item>
        <ListGroup.Item
          className={getActiveTab("schedule", "", getScheduledInfo()) + " p-0"}
        >
          {_getTabLink(
            "Schedule & Publish:",
            getScheduledInfo(),
            "/campaigns/edit/schedule"
          )}{" "}
        </ListGroup.Item>
        {selectedTemplate != "..." ? (
          <div className={Classes.testButtonDiv}>
            <CampaignsTest
              popupHandler={_popUpHandler}
              show={show}
              campaign={campaign}
            />
            <Button
              type="button"
              className="btn"
              variant="primary"
              onClick={() => _popUpHandler(true)}
            >
              Test campaign
            </Button>
          </div>
        ) : (
          <></>
        )}
      </ListGroup>
    </Card>
  );
};

export default ProgressBar;
