import { useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css"
import {Row, Col} from "react-bootstrap"
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateCampaign, loadCampaign } from "../../store/slices/campaigns/createCampaign";
import { 
    createCampaignRecurrence, 
    updateCampaignRecurrence, 
    loadCampaignRecurrence 
} from "../../store/slices/campaigns/createCampaignRecurrence";
import ScheduleRepeatOnWeek from "../../components/Campaigns/ScheduleRepeatOnWeek"
import ScheduleRepeatOnMonth from "../../components/Campaigns/ScheduleRepeatOnMonth"
import DatePicker from "react-datepicker"
import moment from "moment"
import { getCampaigns } from "../../store/slices/campaigns/campaigns";


const SetCampaignSchedule = () => {
    const { campaign, updateCampData } = useSelector((state) => state.createCampaignSlice);
    const { campaignRecurrence } = useSelector((state) => state.createCampaignRecurrenceSlice);
    const [refreshStep, setRefreshStep] = useState(false)
    const [durationType, setDurationType] = useState('Day')
    const [repeatEvery, setRepeatEvery] = useState(1)
    const [endsOn, setEndsOn] = useState('')
    const [endsOnCampaign, setEndsOnCampaign] = useState('')
    const [weekDays, setWeekDays] = useState([0])
    const [monthDay, setMonthDay] = useState('')
    const [isrepeatEveryValid, setIsrepeatEveryValid] = useState(true)

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    
    let scheduleSchema = Yup.object().shape({
        campaign: Yup.number().required('Campaign Id is required.'),
        durationType: Yup.string().required('Repeat type is required.'),
    });

    
    useEffect(()=>{
        let campaignId = params.id
        if(campaignId){
            dispatch(loadCampaign(campaignId))
            console.log('loading campain data from step third !!!')
            dispatch(loadCampaignRecurrence(campaignId))
        }
        
    },[params.id, dispatch, refreshStep])

    useEffect(()=>{
        if(!endsOn && campaign?.data?.scheduledDateTime){
            setEndsOn(new Date(campaign.data.scheduledDateTime))
        }

    },[campaign, endsOn])

    useEffect(()=>{
        if(campaignRecurrence){
            let endsOnDate = campaignRecurrence?.endsOn?campaignRecurrence?.endsOn:campaign?.data.scheduledDateTime
            setRepeatEvery(campaignRecurrence?.repeatEvery)
            setDurationType(campaignRecurrence?.durationType)
            if(endsOnDate){
                setEndsOn(new Date(endsOnDate))
                if(campaignRecurrence?.endsOn){
                    setEndsOnCampaign('endOnDate')
                }
            }
            let repeatOnObj = campaignRecurrence?.repeatOn
            if(repeatOnObj && Object.keys(repeatOnObj).length){
                if(repeatOnObj.days){
                    setWeekDays(repeatOnObj.days)
                }

                if(repeatOnObj.weekDay){
                    let weekDayStr = repeatOnObj.weekDay.join(':')
                    setMonthDay(weekDayStr)
                }

            }
            
        }
       
    },[campaignRecurrence])

    const durationTypeChangeHandler = (e) =>{
        try{
            console.log('current durationType - ', e.currentTarget.value)
            setDurationType(e.currentTarget.value)
        }catch(error){
            console.log(error)
        }
    }
 
    const submitHandler = async (status) => {
        try{
            let repeatOnJson = ''
            if(durationType==='Month'){
                if(monthDay.indexOf(':')){
                    let weekIndexAndWeekDay = monthDay.split(':')
                    repeatOnJson={weekDay:weekIndexAndWeekDay}
                }else{
                    repeatOnJson={date:monthDay}
                }
                
            }else if(durationType==='Week'){
                repeatOnJson={days:weekDays}
            }else{
                repeatOnJson={}
            }

            if(!isrepeatEveryValid) return 
            let payload = {
                campaign:campaign?.data.id,
                durationType:durationType,
                repeatEvery:repeatEvery,
                repeatOn:repeatOnJson,
                endsOn:endsOnCampaign==='endOnDate'?moment(endsOn).format('YYYY-MM-DD'):''
            }
           console.log('campaign publish status...', status, payload)
           await scheduleSchema.isValid(payload).then(function (valid) {
                if(valid){
                    if(campaignRecurrence?.id>0){
                        payload.id = campaignRecurrence?.id
                        dispatch(createCampaignRecurrence(payload))
                    }else{
                        dispatch(createCampaignRecurrence(payload))
                    }
                }
                let campaignPayload = {
                    id:campaign?.data.id,
                    isRepeat:campaign?.data.isRepeat,
                    status:status?status:'published'
                }
                //update campaign status
                dispatch(updateCampaign(campaignPayload))
                //getCampaigns to refresh campaign list page
                dispatch(getCampaigns({ page:1, filterData:'' }));
                setTimeout(()=>{navigate('/campaigns')},200)
                
                
            }).catch((error)=>{
                console.log('Eroorrrrrrrrrr - ',error)
            })
            
        }catch(error){
            console.log('Error:', error)
        }
    } 

    const _endsOnChangeHandler = (e) =>{
        try{
            console.log('EndsOn type - ',e.currentTarget.value)
            setEndsOnCampaign(e.currentTarget.value)
        }catch(error){
            console.log(error)
        }
    }

    const weeekDays = [
        {index:0, name:'S'}, {index:1, name:'M'}, {index:2, name:'T'}, {index:3, name:'W'}, {index:4, name:'T'}, {index:5, name:'F'}, {index:6, name:'S'}
    ]

    

    return (
        <Layout>
            <Row>
                <Col md={8}>
                    <div className="pageHeader mb-6">
                        <h3 className="smallHeading mb-3">Create Campaign</h3>
                        <h1>Schedule &amp; Publish</h1>
                        
                    </div>
                    <div className="progressSteps mt-5 mb-5" style={{maxWidth:'400px'}}>
                        <Row>
                            <Col sm={4}><label>Repeat Every</label></Col>
                            <Col sm={8}>
                                <Row>
                                    <Col sm={4}>
                                    <input type="number" 
                                    className="form-control" 
                                    min="1" 
                                    name="repeatEvery" 
                                    id="repeatEvery" 
                                    onChange={(e)=>{
                                        setRepeatEvery(e.currentTarget.value)
                                        if(e.currentTarget.value<1){
                                            setIsrepeatEveryValid(false)
                                        }else{
                                            setIsrepeatEveryValid(true)
                                        }
                                        
                                    }} 
                                    value={repeatEvery} />

                                    </Col>
                                    <Col sm={8}>
                                    <select 
                                    className="form-select" 
                                    name="durationType" 
                                    value={durationType} 
                                    onChange={durationTypeChangeHandler} >
                                        <option>Day</option>
                                        <option>Week</option>
                                        <option>Month</option>
                                        <option>Year</option>
                                    </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {!isrepeatEveryValid && <Row><Col className="text-danger">Please enter repeat every greater then 0. </Col></Row>}            
                        
                        {durationType==='Week' && <div className="mt-4 mb-2">Repeat on </div>}
                        <Row>
                       {durationType==='Week' &&  
                       weeekDays.map((day)=>{
                        return <ScheduleRepeatOnWeek 
                        key={'day_'+day.index}
                        weekIndex={day.index} 
                        weekName={day.name} 
                        setWeekDays={setWeekDays} 
                        weekDays={weekDays}
                         />
                       })
                       }
                       </Row>
                       {durationType==='Month' &&  
                       <ScheduleRepeatOnMonth 
                       campaign={campaign} 
                       setMonthDay={setMonthDay} monthDay={monthDay} />}
                        <div className="mt-4">
                            <Row>
                                <Col>
                                    <p>Ends</p>
                                    <Row className="mb-3">
                                        <Col>
                                        <input 
                                        type="radio" 
                                        id="endsNever" 
                                        checked={(endsOnCampaign==='Never' || endsOnCampaign==='')?true:false}
                                        value="Never"
                                        name="endsOnCampaign" 
                                        onChange={_endsOnChangeHandler}
                                        /> <label htmlFor="endsNever">Never</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <input 
                                            type="radio" 
                                            id="endsOnDate" 
                                            value="endOnDate" 
                                            checked={endsOnCampaign==='endOnDate'?true:false}
                                            name="endsOnCampaign" 
                                            onChange={_endsOnChangeHandler}
                                            /> <label htmlFor="endsOnDate">On</label>
                                        </Col>
                                        <Col sm={6}>
                                        <DatePicker 
                                            selected={endsOn}
                                            dateFormat="yyyy-MM-d" 
                                            minDate={new Date(campaign?.data?.scheduledDateTime)}
                                            className={endsOnCampaign==='endOnDate'?'form-control':Classes.disable + ' form-control'}
                                            name="endsOn"
                                            onChange={(date)=>{
                                                console.log('New selected date - ',date)
                                                setEndsOn(date)
                                            }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <CampaignFooter 
                        nextStep="Schedule the campaign" 
                        buttonTitle="Save &amp; Publish " 
                        onNextStepHandler={submitHandler} 
                        showDraft={true}

                     />
                </Col> 
                <Col md={4} className={Classes.sidebarBackground}>
                    <div className={Classes.sidebarBackground} style={{height:'100VH'}}>
                        <ProgressBar 
                        campaign={campaign} 
                        updateCampData={updateCampData} 
                        campaignRecurrence={campaignRecurrence} />
                    </div>
                    
                </Col>
            </Row>
        </Layout>
    )
}

export default SetCampaignSchedule;