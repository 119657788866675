import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import ChannelsService from "../../../services/channels";

export const getChannels = createAsyncThunk(
  "users/getUsers",
  async (thunkAPI) => {
    try {
      console.log('filter channels params ............okkkkkk')
      const result = await ChannelsService.getChannels();
      
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { channels: null };

const channelsSlice = createSlice({
  name: "channels",
  initialState,

  extraReducers: {
    [getChannels.fulfilled]: (state, action) => {
      console.log("getChannels.fulfilled", action);
      state.channels = action.payload;
    },
    [getChannels.rejected]: (state, action) => {
      state.channels = null;
      console.log(action)
    },
  },
});

export default channelsSlice.reducer;
