export const weekNameOfDay = (date) => {
  try {
    let dateObj = new Date(date);
    let weekNameNum = Math.ceil(dateObj.getDate() / 7);
    let weekNames = [
      { label: "first", value: 1 },
      { label: "second", value: 2 },
      { label: "third", value: 3 },
      { label: "fourth", value: 4 },
      { label: "fifth", value: 5 },
    ];
    //let weekLocation = Object.keys(weekName).find(key => weekName[key] === weekNameNum);
    let weekLocation = weekNames.find((week) => week.value === weekNameNum);
    return weekLocation;
  } catch (error) {
    console.log(error);
  }
};

export const getWeekDayIndexByName = (weekName) => {
  try {
    const weekDaysArr = [
      { index: 0, name: "Sunday" },
      { index: 1, name: "Monday" },
      { index: 2, name: "Tuesday" },
      { index: 3, name: "Wednesday" },
      { index: 4, name: "Thursday" },
      { index: 5, name: "Friday" },
      { index: 6, name: "Saturday" },
    ];
    let weekDay = weekDaysArr.find((day) => day.name === weekName);
    return weekDay.index;
  } catch (error) {}
};

export const getQueryPlaceHolders = (sqlQuery) => {
  try {
    let placeholders = "";
    let placeholderArr = [];
    let placeholdersVar = [];
    let sqlQUeryLC = sqlQuery.toLowerCase()
    let startOffset = sqlQUeryLC.indexOf("select") + 6;
    let endOffset = sqlQUeryLC.indexOf("from");
    placeholders = sqlQUeryLC.substring(startOffset, endOffset);
    if (placeholders) {
      placeholders = placeholders.trim();
    }

    placeholderArr = placeholders.split(",");

    placeholdersVar = placeholderArr.map((item) => {
      let asExist = item.toLowerCase().indexOf("as");
      let fieldName = "";
      if (asExist > 0) {
        fieldName = item.split("as")[1].trim();
      } else {
        fieldName = item.trim();
      }
      return fieldName;
    });

    return placeholdersVar;
  } catch (error) {
    console.log("Error in _getPlaceHolders function ", error);
    return null;
  }
};

export const isValidEmail = (email) => {
  try {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );
  } catch (err) {
    console.log("Notification : Error in Email validation ", err);
    return err;
  }
};

export const isValidMobile = (mobile) => {
  try {
    return /^[6,7,8,9]\d{9}$/.test(mobile);
  } catch (err) {
    console.log("Notification : Error in mobile validation ", err);
    return err;
  }
};

export const copyselection = (elmId) => {
  // Get the text field
  let r = document.createRange();
  r.selectNode(document.getElementById(elmId));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
};

export const getQueryTables = (sqlQuery) => {
  let tablesList = [];
  try {
    let pattern = /(?<=from|join)\s+(\w+)/gmi;

    let matched = sqlQuery.match(pattern);
    if (matched) {
      tablesList = matched.map((item) => item.trim());
    }

    return tablesList;
  } catch (error) {
    console.log(error);
  }
};

export const sqlQueryRequiredFieldValidate = async (sqlQuery) => {
  try {
    let sqlQueryLC = sqlQuery.toLowerCase()
    let tables = await getQueryTables(sqlQueryLC);
    let tablesWithRequiredField = [];
    console.log("sqlQueryRequiredFieldValidate tables - ###", tables)
    tables.map((table) => {
      if (table === "defaulters") {
        tablesWithRequiredField.push("defaulter_id");
      } else if (table === "users") {
        tablesWithRequiredField.push("user_id");
      }
    });
    if (sqlQueryLC.includes('customers as supplier')) {
      tablesWithRequiredField.push("supplier_id");
    }
    if (sqlQueryLC.includes('customers as buyer')) {
      tablesWithRequiredField.push("buyer_id");
    }

    let queryVars = getQueryPlaceHolders(sqlQueryLC);
    console.log("sqlQueryRequiredFieldValidate queryVars - ###", queryVars)
    let isValid = true;
    tablesWithRequiredField.map((item) => {
      let matched = queryVars.some((item2) => {
        return item2 === item;
      });
      //console.log('Is matched - ',matched)
      if (!matched) {
        isValid = matched;
      }
      return ''
    });
    console.log("sqlQueryRequiredFieldValidate isValid - ###", isValid)
    return isValid;
  } catch (error) {
    console.log(error);
  }
};
