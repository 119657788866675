import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getChannels = async () => {
  try {
    
    let token = getToken();
    let result = await apiService.get(API_URL.GET_CHANNELS, token);
    return result;
  
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};


const channelsService = {
  getChannels,
};

export default channelsService;
