export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const SEND_OTP = API_ENDPOINT + "auth/sendOtp";
export const VERIFY_OTP = API_ENDPOINT + "auth/verifyOtp";
export const LOGOUT = API_ENDPOINT + "auth/logout";

export const TEST_CAMPAIGN = API_ENDPOINT + "test/campaigns";

export const GET_CAMPAIGN = API_ENDPOINT + "campaign/";
export const GET_CAMPAIGNS = API_ENDPOINT + "campaign/filter";
export const CAMPAIGN_CREATE = API_ENDPOINT + "campaign/create";
export const CAMPAIGN_UPDATE = API_ENDPOINT + "campaign/update";
export const CAMPAIGN_DELETE = API_ENDPOINT + "campaign/delete";
export const CAMPAIGN_CLONE = API_ENDPOINT + "campaign/clone";
export const CAMPAIGN_ERRORS = API_ENDPOINT + "campaign/errors";
export const CAMPAIGN_ERROR_COUNT = API_ENDPOINT + "campaign/errorcount";

export const GET_USER = API_ENDPOINT + "user/";
export const GET_USERS = API_ENDPOINT + "user/filter";
export const USER_CREATE = API_ENDPOINT + "user/create";
export const USER_UPDATE = API_ENDPOINT + "user/update";
export const USER_DELETE = API_ENDPOINT + "user/delete";

export const GET_AUDIENCE = API_ENDPOINT + "audience/";
export const GET_AUDIENCES = API_ENDPOINT + "audience/filter";
export const AUDIENCE_CREATE = API_ENDPOINT + "audience/create";
export const AUDIENCE_UPDATE = API_ENDPOINT + "audience/update";

export const GET_TEMPLATE = API_ENDPOINT + "template/";
export const GET_TEMPLATES = API_ENDPOINT + "template/filter";
export const TEMPLATE_CREATE = API_ENDPOINT + "template/create";
export const TEMPLATE_UPDATE = API_ENDPOINT + "template/update";
export const TEMPLATE_CLONE = API_ENDPOINT + "template/clone";

export const GET_CAMPAIGNTAG = API_ENDPOINT + "tags/";
export const GET_CAMPAIGNTAGS = API_ENDPOINT + "tags/filter";
export const CAMPAIGNTAG_CREATE = API_ENDPOINT + "tags/create";
export const CAMPAIGNTAG_UPDATE = API_ENDPOINT + "tags/update";

export const GET_BLOCKCONTACT = API_ENDPOINT + "blockrecipient/";
export const GET_BLOCKCONTACTS = API_ENDPOINT + "blockrecipient/filter";
export const BLOCKCONTACT_CREATE = API_ENDPOINT + "blockrecipient/create";
export const BLOCKCONTACT_UPDATE = API_ENDPOINT + "blockrecipient/update";

export const GET_CREDITQ_BUSINESS = API_ENDPOINT + "creditq/business";
export const GET_CREDITQ_BUSINESSESS = API_ENDPOINT + "creditq/business/filter";
export const GET_CREDITQ_SUPPLIERS = API_ENDPOINT + "creditq/suppliers/filter";
export const GET_CREDITQ_DEFAULTERS = API_ENDPOINT + "creditq/defaulters/filter";
export const GET_CREDITQ_USERS = API_ENDPOINT + "creditq/users/filter";
export const GET_CREDITQ_BLOCKDETAILS = API_ENDPOINT + "creditq/contact/filter";


export const GET_AUDIENCE_QUERY = API_ENDPOINT + "audience/query";

export const GET_CAMPAIGN_RECURRENCE =
  API_ENDPOINT + "campaignrecurrence/details/";
export const CAMPAIGN_RECURRENCE_CREATE =
  API_ENDPOINT + "campaignrecurrence/create";
export const CAMPAIGN_RECURRENCE_UPDATE =
  API_ENDPOINT + "campaignrecurrence/update";

export const GET_CHANNELS = API_ENDPOINT + "channel/filter";

export const GET_GUST_AUDIENCE = API_ENDPOINT + "gustAudiences/get";
export const GUST_AUDIENCE_CREATE = API_ENDPOINT + "gustAudience/save";
export const GUST_AUDIENCE_UPDATE = API_ENDPOINT + "gustAudience/update";
