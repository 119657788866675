import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "../message";
import CampaignsService from "../../../services/campagins";

export const getCampaignErrors = createAsyncThunk(
  "campagins/errors",
  async ({page, filterData}, thunkAPI) => {
    try {
      const result = await CampaignsService.getCampaignErrors(page, filterData);
      console.log('campaign errors.....', result)
      if (result?.data) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCampaignErrorCount = createAsyncThunk(
    "campagins/errorcount",
    async (thunkAPI) => {
      try {
        console.log('campaign errors getCampaignErrorCount thunk calling.....')
        const result = await CampaignsService.getCampaignErrorCount();
        
        if (result) {
          return result;
        } else {
          thunkAPI.dispatch(setMessage(result.data.message));
          return thunkAPI.rejectWithValue();
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

const initialState = {
    campaignErrors: null,
    campaignErrorCount:0
};

const campaignErrorsSlice = createSlice({
  name: "campaignErrors",
  initialState,

  extraReducers: {
    [getCampaignErrors.fulfilled]: (state, action) => {
      console.log("getCampaignErrors.fulfilled", action);
      state.campaignErrors = action.payload;
      state.campaignErrorCount = action.payload.total;
    },
    [getCampaignErrors.rejected]: (state, action) => {
        console.log("getCampaignErrors.rejected", action);
      state.campaignErrors = null;
    },
    [getCampaignErrorCount.fulfilled]: (state, action) => {
        console.log("getCampaignErrorCount.fulfilled", action);
        state.campaignErrorCount = action.payload;
      },
      [getCampaignErrorCount.rejected]: (state, action) => {
          console.log("getCampaignErrorCount.rejected", action);
        state.campaignErrorCount = 0;
      },
  },
});

export default campaignErrorsSlice.reducer;
