import {Modal, Button, Row} from "react-bootstrap";
import DragNDropEmailTemplate from "./DragNDropTemplate";

const ModalBoxForNewTemplate = (props) => {
  const {
    show,
    popupHandler,
    emailEditorContentRef,
    templatePlaceholder,
    emailTemplateSaveHandler,
    emailHtmlContent,
    emailDesignContent,
  } = props;
  const handleClose = () => {
    popupHandler(false);
  };

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DragNDropEmailTemplate
            handleClose={handleClose}
            emailEditorContentRef={emailEditorContentRef}
            emailTemplateSaveHandler={emailTemplateSaveHandler}
            emailHtmlContent={emailHtmlContent}
            emailDesignContent={emailDesignContent}
          />
          <div className="py-4">{templatePlaceholder}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalBoxForNewTemplate;
