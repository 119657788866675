import { useEffect } from "react";
import AsyncSelect  from "react-select/async"
import { useDispatch, useSelector } from "react-redux";
import { getCampaignTags } from "../../store/slices/campaignTags/campaignTags";
import campaignTagsService from "../../services/campaignTags";


const CampaignTagsSelector = (props) =>{
    const {onTagChange, selectedTags,multiOption,showLabel} = props
    const { campaignTags } = useSelector((state) => state.campaignTagsSlice);
    const { refreshTags } = useSelector((state) => state.createCampaignTagSlice);

    const dispatch = useDispatch()

    useEffect(()=>{
        let payload = {filterData:'',page:1}
        dispatch(getCampaignTags(payload))
    },[refreshTags,dispatch]) 
    
    
    const getAllTagOptions = () =>{
        
        let optionsList = []
        if(campaignTags?.data?.length){
            campaignTags.data.map((tag)=>{
                optionsList.push({label:tag.name, value:tag.id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterTags = async (searchKey) =>{
        let optionsList = []
        let filterData={name:searchKey};
        let tags = await campaignTagsService.getCampaignTags(1, filterData)
        console.log('search tags - okkkkkkkkkkkkkkk',tags)
        if(tags?.data?.length){
            tags?.data.map((tag)=>{
                optionsList.push({label:tag.name, value:tag.id})
                return ''
            })
        }
        console.log('Tag options - #######', optionsList)
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterTags(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        {showLabel && <div>Campaign Tag</div>}
        <AsyncSelect 
        value={selectedTags}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllTagOptions()}
        isMulti={multiOption}
        
        />
        </>
    )
}

export default CampaignTagsSelector