
import { Fragment } from "react";
import {Modal, Button} from "react-bootstrap"


const TemplatePreview = (props) =>{
    const {showTestPop, closeTestPop, selectedTemplate} = props

    const handleClose = () => {
        closeTestPop(false)
    }

    const __getTemplateHtml = () => {
        try{
            let templateCOntent = selectedTemplate.templateFor==='email'?selectedTemplate?.emailTemplate?.emailHtml? selectedTemplate.emailTemplate.emailHtml: '' : selectedTemplate.content
            console.log('loading template comtent....', selectedTemplate)
            return (
                <div dangerouslySetInnerHTML={{ __html: templateCOntent }} />
            )
        }catch(error){
            console.log(error)
        }
    }
    
    return (
        <Fragment>
            <Modal show={showTestPop} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Template Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {selectedTemplate &&  __getTemplateHtml()}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
export default TemplatePreview;