import React, { useRef } from "react";
import EmailEditor from "react-email-editor";
import {Button} from "react-bootstrap"

const DragNDropEmailTemplate = (props) => {
  const {emailTemplateSaveHandler, emailHtmlContent, emailDesignContent} = props
  const emailEditorRef = useRef(null);

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      console.log("saveDesign", design);
      alert("Design JSON has been logged in your developer console.");
    });
  };

  const exportHtml = (popupStatus) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      //create a table email template and store JSON and HTML in two columns
      
      if(popupStatus==='close'){
        props.handleClose();
      }
      emailTemplateSaveHandler(design, html)
      
    });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onLoad = () => {
    console.log("onLoad", emailDesignContent);
    // if(emailEditorRef?.current?.editor){
    //   emailEditorRef.current.editor.loadDesign(emailDesignContent)
    // }
  };
  const onReady = () => {
    // editor is ready
    console.log("onReady");
    if(emailEditorRef?.current.editor){
      emailEditorRef.current.editor.loadDesign(emailDesignContent)
    }
  };

  return (
    <div className="row">
      <div>
        <Button  onClick={()=>exportHtml('close')}>Save & Close</Button>
      </div>
      
      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
    </div>
  );
};

export default DragNDropEmailTemplate;
