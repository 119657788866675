import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import campaignTagsService from "../../../services/campaignTags";
import { toast } from "react-toastify";

export const deleteCampaignTag = createAsyncThunk(
  "campaignTags/deleteTemplate",
  async (payload, thunkAPI) => {
    try {
      console.log("create user all thunk calling....", payload);
      const result = await campaignTagsService.deleteCampaignTag(payload);
      if (!result?.data?.error) {
        toast.success(result.data.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  refreshTags: false,
};

const deleteCampaignTagsSlice = createSlice({
  name: "template",
  initialState,
  extraReducers: {
    [deleteCampaignTag.fulfilled]: (state, action) => {
      console.log("deleteCampaignTag.fulfilled...", action.payload);
      state.refreshTags = !state.refreshTags;
    },
    [deleteCampaignTag.rejected]: (state, action) => {
        console.log("deleteCampaignTag.rejected...", action.payload);
    },
  },
});

export default deleteCampaignTagsSlice.reducer;
