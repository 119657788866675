import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import blockContactsService from "../../../services/blockContacts";
import { toast } from "react-toastify";

export const deleteBlockContact = createAsyncThunk(
  "blockcontacts/deleteBlockContact",
  async (payload, thunkAPI) => {
    try {
      console.log("delete block recipient thunk calling....", payload);
      const result = await blockContactsService.deleteBlockContact(payload);
      if (result?.status) {
        toast.success(result.data.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  refreshBlockContactDel: false,
};

const blockContactDeleteSlice = createSlice({
  name: "blockcontactdelete",
  initialState,
  extraReducers: {
    [deleteBlockContact.fulfilled]: (state, action) => {
      console.log("deleteBlockContact.fulfilled...", action.payload);
      state.refreshBlockContactDel = !state.refreshBlockContactDel;
    },
    [deleteBlockContact.rejected]: (_, action) => {
        console.log("deleteBlockContact.rejected...", action.payload);
    },
  },
});

export default blockContactDeleteSlice.reducer;
