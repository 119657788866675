import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
//import Button from "../../components/Button";
import classes from "./style.module.css";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {createBrowserHistory} from "history"

export const FilterForm = (props) => {
  const [scheduledDateTime, setScheduledDateTime] = useState("");
  const { setFilterData,setPage, template } = props;
  const initialValues = {
    name: "",
    status: "",
    scheduledDateTime: scheduledDateTime ? scheduledDateTime : "",
  };

  
  const formik = useFormik({
    initialValues,

    onSubmit: values => {
      
      if(scheduledDateTime){
        values.scheduledDateTime = scheduledDateTime
      }
      if (Object.keys(values).length) {
        Object.keys(values).map((filterKey) => {
          if (!values[filterKey]) {
            delete values[filterKey];
          }
          return "";
        });
      }
      setFilterData((prev) => ({
        ...prev,
        ...values,
      }));
      setPage(1)
      //alert(JSON.stringify(values, null, 2));
      createBrowserHistory().push(`/${template}?page=1`);
    },
  });

  const handleReset = () => {
    props.setLoading(false);
    props.setFilterData("");
    formik.values.name = "";
    formik.values.status = "";
    setScheduledDateTime("");
    createBrowserHistory().push(`/${template}`);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <Col md={5}>
          <div className="form-floating">
            <Link className="btn btn-primary" to="/campaigns/new">Add Campaign</Link>
          </div>
        </Col>
        <Col md={7}>
          <Row>
            <Col>
              <label htmlFor="name">Campaign Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Enter campaign name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Col>
            <Col>
              <label htmlFor="scheduledDateTime">Start Date</label>
              <DatePicker
                selected={scheduledDateTime}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                name="scheduledDateTime"
                onChange={(date) => {
                  console.log("New selected date - ", date);
                  setScheduledDateTime(date);
                  formik.values.name.scheduledDateTime = date;
                  formik.values.scheduledDateTime = date;
                }}
              />
            </Col>
            <Col>
              <label htmlFor="status">Status</label>
              <select
                className="form-select"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value="" label="Select a Status">
                  Select Status{" "}
                </option>
                <option value="draft" label="Draft">
                  {" "}
                  Draft
                </option>
                <option value="published" label="Published">
                  Published
                </option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              {formik.touched.name && formik.errors.name ? (
                <span className="error text-danger">{formik.errors.name}</span>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button as="input" type="submit" value="Filter" xs={4} md={1} />{" "}
              <Button onClick={handleReset} type="reset" variant="secondary">
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};
