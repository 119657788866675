import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import CampaignsService from "../../../services/campagins";

export const getCampaign = createAsyncThunk(
  "campaigns/getCampaign",
  async (id, thunkAPI) => {
    try {
      const result = await CampaignsService.getCampaign(id);
      console.log('getCampaign ######- ', result)
      if (result?.data) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { campaign: {} };

const campaignSlice = createSlice({
  name: "campaign",
  initialState,

  extraReducers: {
    [getCampaign.fulfilled]: (state, action) => {
      state.campaign = action.payload;
    },
    [getCampaign.rejected]: (state, action) => {
      state.campaign = null;
    },
  },
});

export default campaignSlice.reducer;
