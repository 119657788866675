import { useEffect, useState } from "react";
import AsyncSelect  from "react-select/async"
import creditService from "../../services/creditq";

const DefaulterSelector = (props) =>{
    const {onTagChange, selectedVal, supplier} = props
    const [defaulters, setDefaulters]=useState([])
    
    useEffect(()=>{
        getDefaulters()
    },[]) 
    
    const getDefaulters = async () =>{
        let filterData = {search:'', supplier:supplier?supplier:''}
        let results = await creditService.getDefaulters(1, filterData)
        console.log('suppluer Selectes okkkkkkkkkk ',results)
        setDefaulters(results)
    }

    const getAllOptions = () =>{
        
        let optionsList = []
        if(defaulters?.data?.length){
            defaulters.data.map((business)=>{
                optionsList.push({label:`${business.trade_name} - ${business.gst_no}`, value:business.defaulter_id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterList = async (searchKey) =>{
        let optionsList = []
        let filterData = {search:searchKey, supplier:supplier?supplier:''}
        let defaulterList = await creditService.getDefaulters(1, filterData)
        console.log('search business - okkkkkkkkkkkkkkk',defaulterList)
        if(defaulterList?.data?.length){
            defaulterList?.data.map((item)=>{
                optionsList.push({label:`${item.trade_name?item.trade_name:item.company_name} - ${item.gst_no}`, value:item.defaulter_id})
                return ''
            })
        }
        
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterList(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        <AsyncSelect 
        value={selectedVal}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllOptions()}
        />
        </>
    )
}

export default DefaulterSelector