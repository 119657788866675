import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import templatesService from "../../../services/templates";
import {toast} from "react-toastify"

export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async (payload, thunkAPI) => {
    try {
        console.log('template load filter in thunk .....', payload)
      const result = await templatesService.getTemplates(
        payload.page,
        payload.filterData,
        payload.isAll
      );
      console.log('get templates all thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cloneTemplate = createAsyncThunk(
  "templates/cloneTemplate",
  async (payload, thunkAPI) => {
    try {
      const result = await templatesService.cloneTemplate(payload);
      if (result) {
        toast.success("Template cloned successfully.");
        return result;
      } else {
        toast.error("Ops, something went wrong.");
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { templates: null };

const templatesSlice = createSlice({
  name: "templates",
  initialState,

  extraReducers: {
    [getTemplates.fulfilled]: (state, action) => {
      console.log("templates.fulfilled...", action.payload);
      state.templates = action.payload;
    },
    [getTemplates.rejected]: (state, action) => {
      state.templates = null;
    },
    [cloneTemplate.rejected]: (state, action) => {
      state.templates = null;
      //return { ...state, defaulters: [] };
    },
    [cloneTemplate.rejected]: (state, action) => {
      state.templates = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default templatesSlice.reducer;
