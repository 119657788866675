import { useEffect } from "react";
import AsyncSelect  from "react-select/async"
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "../../store/slices/creditq/business";
import creditService from "../../services/creditq";


const BusinessSelector = (props) =>{
    const {onTagChange, selectedVal} = props
    const { businessess } = useSelector((state) => state.businessessSlice);

    const dispatch = useDispatch()

    useEffect(()=>{
        let payload = {filterData:'',page:1}
        dispatch(getBusinesses(payload))
    },[]) 
    
    const getAllOptions = () =>{
        
        let optionsList = []
        if(businessess?.data?.length){
            businessess.data.map((business)=>{
                optionsList.push({label:`${business.trade_name} - ${business.gst_no}`, value:business.id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterBusiness = async (searchKey) =>{
        let optionsList = []
        let businessess = await creditService.getBusinesses(1, searchKey)
        console.log('search business - okkkkkkkkkkkkkkk',businessess)
        if(businessess?.data?.length){
            businessess?.data.map((business)=>{
                optionsList.push({label:`${business.trade_name?business.trade_name:business.company_name} - ${business.gst_no}`, value:business.id})
                return ''
            })
        }
        console.log('Tag options - #######', optionsList)
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterBusiness(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        <AsyncSelect 
        value={selectedVal}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllOptions()}
        />
        </>
    )
}

export default BusinessSelector