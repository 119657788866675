import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import blockContactsService from "../../../services/blockContacts";

export const getBlockContacts = createAsyncThunk(
  "blockContacts/getBlockContacts",
  async (payload, thunkAPI) => {
    try {
        console.log('block receipient load filter in thunk .....', payload)
      const result = await blockContactsService.getBlockContacts(
        payload.page,
        payload.filterData,
        payload.isAll
      );
      console.log('get block receipient all thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { blockContacts: null };

const blockContactsSlice = createSlice({
  name: "blockContacts",
  initialState,

  extraReducers: {
    [getBlockContacts.fulfilled]: (state, action) => {
      console.log("getBlockRecipients.fulfilled...", action.payload);
      state.blockContacts = action.payload;
    },
    [getBlockContacts.rejected]: (state, action) => {
      state.blockContacts = null;
    },
  },
});

export default blockContactsSlice.reducer;
