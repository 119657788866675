import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import CampaignsService from "../../../services/campagins";

export const createCampaignRecurrence = createAsyncThunk(
  "campagins/createCampaignRecurrence",
  async (payload , thunkAPI) => {
    try {
        console.log('create recurrence payload ... ', payload)
        const result = await CampaignsService.getCreateCampaignRecurrence(
        payload
      );
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCampaignRecurrence = createAsyncThunk(
    "campagins/updateCampaignRecurrence",
    async (payload , thunkAPI) => {
      try {
        console.log('updating campaign recurrence data from thunk function ', payload)
        const result = await CampaignsService.getUpdateCampaignRecurrence(
          payload
        );
        if (result) {
          return result;
        } else {
          thunkAPI.dispatch(setMessage(result.data.message));
          return thunkAPI.rejectWithValue();
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

  export const loadCampaignRecurrence = createAsyncThunk(
    "campagins/loadCampaignRecurrence",
    async (id, thunkAPI) => {
      try {
        const result = await CampaignsService.getCampaignRecurrence(id);
        console.log('Recurrence data loaded....', result, id)
        if (result) {
          return result;
        } else {
          thunkAPI.dispatch(setMessage(result.data.message));
          return thunkAPI.rejectWithValue();
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  ); 

const initialState = { 
    campaignRecurrence: null,
};

const createCampaignRecurrenceSlice = createSlice({
  name: "createCampaignRecurrenceSlice",
  initialState,
  reducers:{
    setCampaign(state, action){
        state.campaignRecurrence = {...state.campaignRecurrence,...action.payload}
    },
    reSetCampaignRecurrence(state, _){
      state.campaignRecurrence = null 
  }
  },

  extraReducers: {
    [createCampaignRecurrence.fulfilled]: (state, action) => {
      console.log("createCampaignRecurrence.fulfilled", action);
      state.campaignRecurrence = {...state.campaignRecurrence,...action.payload.data};
    },
    [createCampaignRecurrence.rejected]: (state, action) => {
      //state.campaignRecurrence = null;
    },
    [updateCampaignRecurrence.fulfilled]: (state, action) => {
        console.log("updateCampaignRecurrence.fulfilled okkkkkkkkkk", action);
        state.campaignRecurrence = action.payload.data;
        //state.campaign = action.payload;
      },
      [updateCampaignRecurrence.rejected]: (state, action) => {
        //state.campaign = null;
      },
      [loadCampaignRecurrence.fulfilled]: (state, action) => {
        console.log("loadCampaignRecurrence.fulfilled", action.payload);
        state.campaignRecurrence = action.payload;
      },
      [loadCampaignRecurrence.rejected]: (state, action) => {
        state.campaignRecurrence = null;
      },
  },
});


export const createCampaignRecurrenceActions = createCampaignRecurrenceSlice.actions
export default createCampaignRecurrenceSlice.reducer;


