import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getCampaignTags = async (page, filterData, isAll) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData, isAll };
    console.log("data tags", data);
    let result = await apiService.post(API_URL.GET_CAMPAIGNTAGS, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getCampaignTag = async (id) => {
  try {
    
    let token = getToken();

    let result = await apiService.get(API_URL.GET_CAMPAIGNTAG+id, token);
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const createCampaignTag = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("CREATE campaign tag data #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGNTAG_CREATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const updateCampaignTag = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("data", data);
    let result = await apiService.put(API_URL.CAMPAIGNTAG_UPDATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const deleteCampaignTag = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    
    let result = await apiService.deleteData(API_URL.GET_CAMPAIGNTAG+payload, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const campaignTagsService = {
    getCampaignTags,
    getCampaignTag,
    createCampaignTag,
    updateCampaignTag,
    deleteCampaignTag,
    
};

export default campaignTagsService;
