import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import campaignTagsService from "../../../services/campaignTags";

export const getCampaignTags = createAsyncThunk(
  "campaignTags/getCampaignTags",
  async (payload, thunkAPI) => {
    try {
        console.log('Tags load filter in thunk .....', payload)
      const result = await campaignTagsService.getCampaignTags(
        payload.page,
        payload.filterData,
        payload.isAll
      );
      console.log('get tags all thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { campaignTags: null };

const campaignTagsSlice = createSlice({
  name: "campaignTags",
  initialState,

  extraReducers: {
    [getCampaignTags.fulfilled]: (state, action) => {
      console.log("getCampaignTags.fulfilled...", action.payload);
      state.campaignTags = action.payload;
    },
    [getCampaignTags.rejected]: (state, action) => {
      state.campaignTags = null;
    },
  },
});

export default campaignTagsSlice.reducer;
