import {Fragment} from "react";
import {Modal, Button, Table} from "react-bootstrap";
import SqlQueryResult from "./SqlQueryResult";

const AudiencePreview = (props) => {
  const {audience, showTestPop, closeTestPop, previewAudiences} = props;

  console.log("props######", props);

  const handleClose = () => {
    closeTestPop(false);
  };

  return (
    <Fragment>
      <Modal show={showTestPop} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Results for - {audience.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewAudiences?.data?.length ? (
            <SqlQueryResult queryResults={previewAudiences} />
          ) : (
            <p>No result found!!</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default AudiencePreview;
