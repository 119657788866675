import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "../message";
import AudiencesService from "../../../services/audiences";
import {toast} from "react-toastify";

export const createAudience = createAsyncThunk(
  "campagins/createAudience",
  async (payload, thunkAPI) => {
    try {
      console.log("create audience all thunk calling....");
      const result = await AudiencesService.createAudience(payload);
      if (!result?.data?.error) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateAudience = createAsyncThunk(
  "campagins/updateAudience",
  async (payload, thunkAPI) => {
    try {
      console.log("create audience all thunk calling....", payload);
      const result = await AudiencesService.updateAudience(payload);
      if (!result?.data?.error) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  audience: null,
  audienceCreated: null,
};

const createAudiencesSlice = createSlice({
  name: "audience",
  initialState,

  extraReducers: {
    [createAudience.fulfilled]: (state, action) => {
      console.log("audiences.fulfilled...", action.payload);
      state.audienceCreated = true;
      state.audience = action.payload;
      //return { ...state, audience: action.payload.data };
    },
    [createAudience.rejected]: (state, action) => {
      state.audienceCreated = null;
      state.audience = null;
      // return { ...state, audience: null };
    },
    [updateAudience.fulfilled]: (state, action) => {
      console.log("updateAudience.fulfilled...", action.payload);
      state.audienceCreated = true;
      state.audience = action.payload;
      //return { ...state, audience: action.payload.data };
    },
    [updateAudience.rejected]: (state, action) => {
      //state.audience = null;
      state.audienceCreated = null;
      console.log("updateAudience.rejected...", action.payload);
      // return { ...state, audience: null };
    },
  },
});

export default createAudiencesSlice.reducer;
