import { useFormik } from "formik";
import {useSelector} from "react-redux"
import { Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {createBrowserHistory} from "history"
import { Link } from "react-router-dom";

const TemplatesFilterForm = (props) => {
    
  const { setFilterData, setPage, template } = props;
  const { channels } = useSelector((state) => state.channelsSlice);
  const initialValues = {
    name: "",
    status: "",
    channel:"",
  };

  const getChannelOptions = () => {
    try{
        return channels.data.map((channel)=><option key={channel.name} value={channel.id} label={channel.name}>{channel.name}</option>)
    }catch(error){
        console.log(error)
    }
  }
  
  const formik = useFormik({
    initialValues,

    onSubmit: values => {
      
      if (Object.keys(values).length) {
        Object.keys(values).map((filterKey) => {
          if (!values[filterKey]) {
            delete values[filterKey];
          }
          return "";
        });
      }
      
      let channelData ={...values}
      if(values.channel){
        channelData.channel = {id:values.channel}
      }
       
      setFilterData(channelData);
      setPage(1)
      createBrowserHistory().push(`/${template}?page=1`);
    },
  });

  const handleReset = () => {
    props.setLoading(false);
    props.setFilterData("");
    formik.values.name = "";
    formik.values.status = "";
    formik.values.channel = "";
    
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <Col md={4}>
          <div className="form-floating">
            <Link className="btn btn-primary" to="/templates/new">Add Template</Link>
          </div>
        </Col>
        <Col md={8}>
          <Row>
            <Col>
              <label htmlFor="name">Name</label>
              <input
                name="name"
                id="name"
                type="text"
                className="form-control"
                placeholder="Enter name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Col>
            <Col>
            
              <label htmlFor="email">Email</label>
              <select
                className="form-select"
                name="channel"
                value={formik.values.channel}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value='' label="Select a Channel">Select a Channel{" "}</option>
                {getChannelOptions()}
              </select>
            </Col>
            
            <Col>
              <label htmlFor="status">Status</label>
              <select
                className="form-select"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value="" label="Select a Status">
                  Select Status{" "}
                </option>
                <option value="draft" label="Draft">
                  {" "}
                  Draft
                </option>
                <option value="published" label="Published">
                Published
                </option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              {formik.touched.name && formik.errors.name ? (
                <span className="error text-danger">{formik.errors.name}</span>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button as="input" type="submit" value="Filter" xs={4} md={1} />{" "}
              <Button onClick={handleReset} type="reset" variant="secondary">
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default TemplatesFilterForm;