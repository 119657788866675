import {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css";
import {Row, Col} from "react-bootstrap";
import ProgressBar from "../../components/Campaigns/ProgressBar";
import {useSelector, useDispatch} from "react-redux";
import {loadCampaign} from "../../store/slices/campaigns/createCampaign";
import {getTemplate} from "../../store/slices/templates/template";
import CreateTemplateForm from "../../components/Template/NewTemplateForm";
import CreateTemplateFormForSms from "../../components/Template/NewTemplateFormSms";
import CreateTemplateFormForWhatsapp from "../../components/Template/NewTemplateFormWhatsapp";
import {getQueryPlaceHolders} from "../../services/utils";
import {getAudienceSelectedKeys} from "../../services/audiences/index";

const EditTemplate = () => {
  const {campaign} = useSelector((state) => state.createCampaignSlice);
  // const {audience} = useSelector((state) => state.audienceSlice);
  const {emailTemplate} = useSelector((state) => state.emailTemplateSlice);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [placeholderArr, setplaceholderArr] = useState([]);

  useEffect(() => {
    let templateId = params.templateId;
    let campaignId = params.id;
    if (templateId) {
      // console.log('template loading...for edit ', templateId, emailTemplate)
      dispatch(getTemplate(templateId));
    }

    if (!campaign) {
      dispatch(loadCampaign(campaignId));
    }
  }, [dispatch]);

  const updateTemplateDropDownHandler = () => {
    try {
      let campaignId = params.id;
      if (campaignId) {
        navigate(`/campaigns/edit/template/${campaignId}`);
      }
      console.log(
        "refresh template list in dropdown. Redirect to create new campaign page"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const _copyselection = (elmId) => {
    // Get the text field
    let r = document.createRange();
    r.selectNode(document.getElementById(elmId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  useEffect(() => {
    console.log("campaign######$$$$$", campaign);
    if (campaign) {
      getPlaceholderData();
    }
  }, [campaign]);

  const getPlaceholderData = async () => {
    let placeholder;
    console.log("get placeholder component sql - ", campaign);
    if (campaign?.data?.audience?.hasGuestAudience === "creditq") {
      placeholder = getQueryPlaceHolders(campaign?.data?.audience?.sqlQuery);
    } else {
      let audienceId = campaign?.data?.audience?.id;

      let audienceData = await getAudienceSelectedKeys(audienceId);

      if (audienceData.audienceQueryVar[0].selectKeys.data.length) {
        let queryVars = audienceData.audienceQueryVar[0].selectKeys.data
          ? audienceData.audienceQueryVar[0].selectKeys.data
          : [];
        console.log("queryVars#####", queryVars);
        placeholder = queryVars;
      }
    }

    console.log("placeholder ##### - ", placeholder);
    setplaceholderArr(placeholder);
  };
  const _getPlaceHolderFromAudienceQuery = () => {
    try {
      console.log("placeholderArr####@@@@@", placeholderArr);
      if (!placeholderArr.length) return;
      return placeholderArr.map((item) => {
        let asExist = item.indexOf("as");
        let fieldName = "";
        if (asExist > 0) {
          fieldName = item.split("as")[1].trim();
        } else {
          fieldName = item.trim();
        }
        return (
          <span key={fieldName} className="inline-block px-2">
            <span id={fieldName}>{`{{${fieldName}}}`}</span>{" "}
            <svg
              onClick={() => _copyselection(fieldName)}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg>
          </span>
        );
      });
    } catch (error) {
      console.log("error#####", error);
    }
  };

  return (
    <Layout>
      <Row>
        <Col md={8}>
          <div className="pageHeader mb-6">
            <h3 className="smallHeading mb-3">
              Update Template: {emailTemplate?.name}
            </h3>
          </div>
          {emailTemplate?.name && (
            <div className="progressSteps mt-5 mb-5">
              {campaign?.data?.channel?.id === 1 && (
                <CreateTemplateForm
                  campaign={campaign}
                  onNewTemplateSubmit={updateTemplateDropDownHandler}
                  templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                  template={emailTemplate}
                  isEdit={true}
                />
              )}
              {campaign?.data?.channel?.id === 2 && (
                <CreateTemplateFormForSms
                  campaign={campaign}
                  onNewTemplateSubmit={updateTemplateDropDownHandler}
                  template={emailTemplate}
                  templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                  isEdit={true}
                />
              )}

              {campaign?.data?.channel?.id === 4 && (
                <CreateTemplateFormForWhatsapp
                  campaign={campaign}
                  onNewTemplateSubmit={updateTemplateDropDownHandler}
                  template={emailTemplate}
                  templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                  isEdit={true}
                />
              )}
            </div>
          )}
          {/* <div><p>Following placeholders are supported in this template</p>
                        {_getPlaceHolderFromAudienceQuery()}
                    </div> */}
        </Col>
        <Col md={4} className={Classes.sidebarBackground}>
          <div className={Classes.sidebarBackground} style={{height: "100VH"}}>
            <ProgressBar campaign={campaign} />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default EditTemplate;
