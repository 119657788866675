import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import blockContactsService from "../../../services/blockContacts";

export const getBlockContact = createAsyncThunk(
  "blockRecipient/getBlockRecipients",
  async (payload, thunkAPI) => {
    try {
        console.log('block receipient load filter in thunk .....', payload)
      const result = await blockContactsService.getBlockContact(
        payload.page,
        payload.filterData,
        payload.isAll
      );
      console.log('get block receipient all thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { blockContact: null };

const blockContactSlice = createSlice({
  name: "blockContact",
  initialState,

  extraReducers: {
    [getBlockContact.fulfilled]: (state, action) => {
      console.log("getBlockContact.fulfilled...", action.payload);
      state.blockContact = action.payload;
    },
    [getBlockContact.rejected]: (state, action) => {
      state.blockContact = null;
    },
  },
});

export default blockContactSlice.reducer;
