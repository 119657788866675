import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "../message";
import CampaignsService from "../../../services/campagins";
import {toast} from "react-toastify";

export const getAudienceCampaigns = createAsyncThunk(
  "audience/getAudienceCampaigns",
  async ({page, filterData}, thunkAPI) => {
    try {
      const result = await CampaignsService.getCampaigns(page, filterData);
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {audienceCampaigns: null};

const audienceCampaignsSlice = createSlice({
  name: "audienceCampaigns",
  initialState,

  extraReducers: {
    [getAudienceCampaigns.fulfilled]: (state, action) => {
      console.log("getAudienceCampaigns.fulfilled", action);
      state.audienceCampaigns = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getAudienceCampaigns.rejected]: (state, action) => {
      state.audienceCampaigns = null;
      //return { ...state, defaulters: [] };
    },
    
  },
});

export default audienceCampaignsSlice.reducer;
