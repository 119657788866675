import { useEffect, useState } from "react";
import {format } from "date-fns"
import { useSelector } from "react-redux";


const TimePicker = (props) =>{
    const {startTime, setStartTime, onStartTimeChange} = props
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('00')
    const campaign  = useSelector((state) => state.createCampaignSlice.campaign);

    useEffect(()=>{
        console.log('minute time change handler....loading', campaign)
        setHours(getTimePart('h'))
        setMinutes(getTimePart('m'))
        let finaleTime = getTimePart('h')?`${getTimePart('h')}:${getTimePart('m')}`:''
        setStartTime(finaleTime)
    },[campaign])

    const getHours = () =>{
        let hours = [];
        for (let idx=1; idx<=24; idx++) {
            let doubleDigitChk = idx>9?idx : '0'+idx
            hours.push(<option value={doubleDigitChk} key={doubleDigitChk}>{doubleDigitChk}</option>);
        }
        return hours; 
    }
    const getTimePart = (type) => {
        try{
			let sHour =''
			let sMinute=''
            if(campaign?.startTime){
				[sHour, sMinute] = campaign?.startTime.split(':')
            }else if(campaign?.data.scheduledDateTime){
				 [sHour, sMinute] = format(new Date(campaign?.data.scheduledDateTime),'HH:mm').split(':')
            }
            return type==='m'? sMinute:sHour
        }catch(error){
			console.log(error)
        }	
    }

    const getMinutes = () =>{
        let minutes = [];
        for (let idx=1; idx<=60; idx++) {
            let doubleDigitChk = idx>9?idx : '0'+idx
            minutes.push(<option value={doubleDigitChk} key={doubleDigitChk}>{doubleDigitChk}</option>);
        }
        return minutes; 
    }

    const hTimeChangeHandler = (event) =>{
        try{
            
            setHours(event.currentTarget.value)
            let finaleTime = minutes?`${event.currentTarget.value}:${minutes}`:`${event.currentTarget.value}:00`
            setStartTime(finaleTime)
            onStartTimeChange(finaleTime)
        }catch(error){
            console.log(error)
        }
    }

    const mTimeChangeHandler = (event) =>{
        try{
            console.log('minute time change handler....', startTime)
            setMinutes(event.currentTarget.value)
            let finaleTime = `${hours}:${event.currentTarget.value}`
           setStartTime(finaleTime)
           
        }catch(error){
            console.log(error)
        }
    }

    return (
        <div className="d-flex" style={{gap:'10px'}}>
            <select className="form-control form-select" 
            name="startHours" 
            onChange={hTimeChangeHandler} 
            value={hours} >
                <option value="">select hours</option>
                {getHours()}
            </select>
            <select className="form-control form-select" 
            name="startMinutes" 
            onChange={mTimeChangeHandler} 
            value={minutes}>
                <option value="">select Minutes</option>
                {getMinutes()}
            </select>
        </div>
    )
}

export default TimePicker;