import {useState, useEffect} from "react";
import {Modal, Button, Card, Row, Col, Alert} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Classes from "../Channels/style.module.css";
import {useDispatch} from "react-redux";
import {testCampaigns} from "../../store/slices/campaigns/campaigns";
import {toast} from "react-toastify";

const CampaignsTest = (props) => {
  const {show, popupHandler, campaign} = props;
  const [activeChannel, setActiveChannel] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [showMobileMessage, setShowMobileMessage] = useState(false);
  const [channel, setChannel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("campaign#####", campaign, campaign.data.channel.id);
    if (campaign) {
      setActiveChannel(campaign.data.channel.id);
      setChannel(campaign.data.channel.id);
    }
  }, [campaign]);

  const handleClose = () => {
    popupHandler(false);
    setShowEmailMessage(false);
    setShowMobileMessage(false);
    setEmail("");
    setMobile("");
  };

  const submitHandler = () => {
    console.log("campaign#####", campaign);
    let testCampaignJson = {
      campaignId: campaign.data.id,
      scheduledDateTime: campaign.data.scheduledDateTime,
      isTest: true,
    };
    if (activeChannel == 1) {
      if (email) {
        setShowEmailMessage(false);
        testCampaignJson.channelName = "email";
        testCampaignJson.value = email;
      } else {
        setShowEmailMessage(true);
      }
    }
    if (activeChannel === 2 || activeChannel === 4) {
      if (mobile) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (mobile.match(phoneno)) {
          setShowMobileMessage(false);
          testCampaignJson.channelName = activeChannel===2?"mobile":'whatsapp';
          testCampaignJson.value = mobile;
        } else {
          setShowMobileMessage(true);
        }
      } else {
        setShowMobileMessage(true);
      }
    }
    if (email || mobile) {
      if (testCampaignJson.value) {
        console.log("testCampaignJson#####", testCampaignJson);
        dispatch(testCampaigns(testCampaignJson));
        toast.success("Notification sent to process.");
      }
    }
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Test campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {channel && channel == 1 && (
            <Card
              onClick={() => {
                setShowMobileMessage(false);
                setMobile("");
              }}
              className={Classes.active + " shadow p-3 mb-5 bg-white rounded"}
            >
              <Card.Body>
                <Row>
                  <Col>
                    <Alert show={showEmailMessage} variant="success">
                      <Alert.Heading>Error</Alert.Heading>
                      <p>Please enter the valid email id!</p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => setShowEmailMessage(false)}
                          variant="outline-success"
                        >
                          Close this alert
                        </Button>
                      </div>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                    </svg>
                  </Col>
                  <Col md={10}>
                    <h3 className={Classes.blockHeading}>Email</h3>
                    {activeChannel === 1 ? (
                      <Col>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            onClick={() => submitHandler()}
                            type="button"
                          >
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          {channel && (channel === 2 || channel === 4) && (
            <Card
              onClick={() => {
                setShowEmailMessage(false);
                setEmail("");
              }}
              className={Classes.active + " shadow p-3 mb-5 bg-white rounded"}
            >
              <Card.Body>
                <Row>
                  <Col>
                    <Alert show={showMobileMessage} variant="success">
                      <Alert.Heading>Error</Alert.Heading>
                      <p>Please enter the valid Mobile Number!</p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => setShowMobileMessage(false)}
                          variant="outline-success"
                        >
                          Close this alert
                        </Button>
                      </div>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      className="bi bi-phone-vibrate"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z" />
                      <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z" />
                    </svg>
                  </Col>
                  <Col md={10}>
                    <h3 className={Classes.blockHeading}>Mobile</h3>
                    {activeChannel === 2 || activeChannel === 4 ? (
                      <Col>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicMobile"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="mobile"
                              placeholder="Enter mobile number"
                              value={mobile ? mobile.val : ""}
                              onChange={(e) => setMobile(e.target.value)}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            onClick={() => submitHandler()}
                            type="button"
                          >
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignsTest;
