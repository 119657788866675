import React, { useState, useEffect } from "react";
import Layout from "../../layouts/default";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../store/slices/users/users";
import { Table} from "react-bootstrap";
import {format } from "date-fns"
import UsersFilterForm from "../../components/Users/FilterForm"
import UsersForm from "../../components/Users/Form"
import UIModalDialog from "../../components/UI/ModalDialog"
import PaginationList from "../../components/Pagination";
import {ICONS} from "../../constants/icons"
import Classes  from "./style.module.css";
import UsersDetail from "../../components/Users/Detail";
import {deleteUser} from "../../store/slices/users/createUser"

export const Users = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState('');
  const [isShow, setIsShow] = useState(false)
  const [isShowInfo, setIsShowInfo] = useState(false)
  const [user, setUser] = useState(false)
  const { users } = useSelector((state) => state.usersSlice);
  const { reloadUsers } = useSelector((state) => state.createUserSlice);
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getUsers({ page, filterData }));
  },[dispatch, loading, page, filterData,reloadUsers])

  const addNewModalOpen = () =>{
    setIsShow(true)
    setUser('')
  }

  const userDetailsModalOpen = async (id) =>{
    console.log('User infow window open.....')
    let userInfo = await getEditUserInfo(id)
    setUser(userInfo)
    setIsShowInfo(true)
  }
  
  const addNewSubmitHandler = () =>{
    setLoading(!loading)
    setIsShow(false)
  }

  const EditModalOpenHandler = async (id) =>{
    
    let userInfo = await getEditUserInfo(id)
    setUser(userInfo)
    console.log(userInfo)
    setIsShow(true)
  }
  const getEditUserInfo = async (id) =>{
    try{
      let userInfo = {}
      if(users?.data.length){
        //console.log('edit record.......', users)
        userInfo = users.data.find((item)=>item.id===id)
      }
      return userInfo
    }catch(error){
      console.log(error)
    }
  }
  
  let paginationData = {};
  if (users) {
    paginationData = {
      total:users.total, 
      recordsPerPage:users.recordsPerPage,
      page:paginationData.page,
      template:'users'
    };
  }

  const __deleteHandler = (id) => {
    try{
      let confirmDelete = window.confirm("Are you sure to delete this User?")
       
      if(!confirmDelete){
        console.log('sorry....revert')
        return 
      }
       
      dispatch(deleteUser({id}))
      
    }catch(error){
      console.log(error)
    }
  }

  const _tableHeader = () => {
    return (
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Mobile</th>
        <th>Email</th>
        <th>Role</th>
        <th>Status</th>
        <th>Created On</th>
        <th>Modified On</th>
        <th>Action</th>
      </tr>
      </thead>
    )
  }

  const _userList = () => {
    let pageOffset = +users?.recordsPerPage*(page-1)
    return users.data.map((item, index)=>{
      return (
        
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.name}</td>
          <td>{item.mobile}</td>
          <td>{item.email}</td>
          <td>{item?.role?.name}</td>
          <td>{item.status===1?'Active':'Inactive'}</td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td>{format(new Date(item.updatedAt), 'yyyy-MM-dd')}</td>
          <td className={Classes.actions}>
            <span onClick={()=>EditModalOpenHandler(item.id)}><img src={ICONS.edit_action} alt="Edit" /></span> 
            <span onClick={()=>userDetailsModalOpen(item.id)}><img src={ICONS.view_action} alt="View" /></span>
            <span style={{cursor:"pointer"}} onClick={()=>__deleteHandler(item.id)}>
              <img src={ICONS.delete_action} alt="Delete" />
            </span>
            </td>
        </tr>
        
      )
    })
  }
  
  return <Layout>
    <UsersFilterForm 
    setLoading={setLoading} 
    setFilterData={setFilterData} page={page} 
    setPage={setPage} 
    onAddNew={addNewModalOpen} 
    template={paginationData.template}
    />
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {users && <tbody>{_userList()}</tbody>}
    </Table>
    {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    <UIModalDialog 
    title="Add New User" 
    isShow={isShow} 
    setIsShow={setIsShow} 
    modalType="edit"
    >
      <UsersForm onNewSubmit={addNewSubmitHandler} user={user}  />
    </UIModalDialog>
    <UIModalDialog title="User Details" 
    isShow={isShowInfo} 
    setIsShowInfo={setIsShowInfo}
    modalType="details"
     >
      <UsersDetail user={user}  />
    </UIModalDialog>
  </Layout>;
};