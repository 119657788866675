import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import campaignTagsService from "../../../services/campaignTags";
import {toast} from "react-toastify"

export const createCampaignTag = createAsyncThunk(
  "campaginsTags/createCampaignTag",
  async (payload, thunkAPI) => {
    try {
        console.log('create tag all thunk calling....')
      const result = await campaignTagsService.createCampaignTag(
        payload
      );
      if (!result?.data?.error) {

        toast.success("tag created successfully.")
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCampaignTag = createAsyncThunk(
  "campaginsTags/updateCampaignTag",
  async (payload , thunkAPI) => {
    try {
      console.log('updating tags data from thunk function ', payload)
      const result = await campaignTagsService.updateCampaignTag(
        payload
      );
      if (!result?.data?.error) {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.success(result.data.message)
        return result;

      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { 
  campaignTag: null,
  refreshTags:false
};

const createCampaignTagSlice = createSlice({
  name: "campaignTag",
  initialState,
  reducers:{
    reSetTemplate(state, _){
      state.campaignTag = null 
    }
  },
  extraReducers: {
    [createCampaignTag.fulfilled]: (state, action) => {
      console.log("createCampaignTag.fulfilled...", action.payload);
      state.campaignTag=action.payload;
      state.refreshTags = !state.refreshTags;
    },
    [createCampaignTag.rejected]: (state, action) => {
      state.template = null;
    },
    [updateCampaignTag.fulfilled]: (state, action) => {
      console.log("updateCampaignTag.fulfilled okkkkkkkkkk", action);
      state.campaignTag=action.payload;
      state.refreshTags = !state.refreshTags;
    },
    [updateCampaignTag.rejected]: (state, action) => {
      //state.campaign = null;
      console.log("updateCampaignTag.rejected okkkkkkkkkk", action);
    }
  },
});

export default createCampaignTagSlice.reducer;
