import { useEffect, useState } from "react";
import AsyncSelect  from "react-select/async"
import creditService from "../../services/creditq";


const SupplierSelector = (props) =>{
    const {onTagChange, selectedVal} = props
    const [suppliers, setSuppliers]=useState([])
    
    useEffect(()=>{
        getSuppliers()
    },[]) 
    
    const getSuppliers = async () =>{
        let results = await creditService.getSuppliers(1, '')
        console.log('suppluer Selectes okkkkkkkkkk ',results)
        setSuppliers(results)
    }

    const getAllOptions = () =>{
        
        let optionsList = []
        if(suppliers?.data?.length){
            suppliers.data.map((business)=>{
                optionsList.push({label:`${business.trade_name} - ${business.gst_no}`, value:business.id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterList = async (searchKey) =>{
        let optionsList = []
        let supplierList = await creditService.getSuppliers(1, searchKey)
        console.log('search business - okkkkkkkkkkkkkkk',supplierList)
        if(supplierList?.data?.length){
            supplierList?.data.map((item)=>{
                optionsList.push({label:`${item.trade_name?item.trade_name:item.company_name} - ${item.gst_no}`, value:item.id})
                return ''
            })
        }
        
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterList(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        <AsyncSelect 
        value={selectedVal}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllOptions()}
        />
        </>
    )
}

export default SupplierSelector