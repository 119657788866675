import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Button} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import {createBlockContact, updateBlockContact} from "../../store/slices/blockContacts/create"
import campaignService from "../../services/campagins";
import CampaignSelector from './CampaignSelector';
import CampaignTagsSelector from "../CampaignTags/Selector"
import campaignTagsService from "../../services/campaignTags";

const getBlockStatus = (contact) =>{
  let blockStatus = 1
  if(contact?.id){
      blockStatus = contact.status 
  }
  return blockStatus
}

const BlockContactFormEmail = (props) =>{
    const {contact, scopeType, blockType,onAddUpdate} = props
    const [status,setStatus] = useState(getBlockStatus(contact))
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [selectedTag, setSelectedTag] = useState('')
    const [isValid, setIsValid] = useState(true)
    const [isValidTag, setIsValidTag] = useState(true)
    const dispatch = useDispatch()

    const emailValidPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const contactSchema = Yup.object().shape({
        blockValue:Yup.string().required('Email is required field').matches(emailValidPattern, 'Please enter a valid email.'),
    });

    useEffect(()=>{
      if(scopeType==='Campaign'){
        getCampaignInfo()
      }
      if(scopeType==='Tags'){
        getTagsInfo()
      }
      
    },[])

    const getCampaignInfo = async () =>{
      try{
        let blockValue = ''
        if(contact?.id){
            let filterData = {id:contact.scopeValue}
            let selectedResult = await campaignService.getCampaigns(1, filterData)
            console.log('Selected Result....',selectedResult, selectedResult.data[0].name)
            blockValue = {label:selectedResult.data[0].name, value:contact.scopeValue} 
            setSelectedCampaign(blockValue)
        }
        
      }catch(error){
        console.log()
      }
    }
    const getTagsInfo = async () =>{
      try{
        let blockValue = ''
        if(contact?.id){
            let selectedResult = await campaignTagsService.getCampaignTag(contact.scopeValue)
            blockValue = {label:selectedResult.data.name, value:contact.scopeValue} 
            setSelectedTag(blockValue)
        }
        
      }catch(error){
        console.log()
      }
    }
    
    const campaignChangeHandler = (event) =>{
      try{
          console.log('selected supplier ', event)
          setSelectedCampaign(event)
          setIsValid(true)
      }catch(error){
          console.log(error)
      }
  }
    const tagChangeHandler = (event) =>{
      try{
          console.log('selected tag ', event)
          setSelectedTag(event)
          setIsValidTag(true)
      }catch(error){
          console.log(error)
      }
  }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            scopeType:  scopeType,
            scopeValue:contact?.scopeValue? contact.scopeValue:0,
            blockType:blockType,
            blockValue:contact?.blockValue? contact.blockValue:'',
            status:status
        },
        validationSchema:contactSchema,

        onSubmit: (values, {resetForm}) => {
            console.log('submitted values #########',values, );
            //block contacts by campaign
            if(scopeType==='Campaign' && !selectedCampaign){
              setIsValid(false)
              return
            }
            if(scopeType==='Campaign' && selectedCampaign){
              values.scopeValue = selectedCampaign.value
            }

            //block contact by campaign tags
            if(scopeType==='Tags' && !selectedTag){
              setIsValidTag(false)
              return
            }
            if(scopeType==='Tags' && selectedTag){
              values.scopeValue = selectedTag.value
            }

            values.status = status
            if(contact?.id){
              values.id = contact.id
              dispatch(updateBlockContact(values));
            }else{
              dispatch(createBlockContact(values));
            }
            
            onAddUpdate()
            resetForm({values:''})
        },
   
      });
      return(
        <form onSubmit={formik.handleSubmit}>
            {scopeType==='Campaign' && <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Campaign</label>
                <div className="col-md-9">
                   <CampaignSelector 
                   onTagChange={campaignChangeHandler}
                   selectedVal={selectedCampaign}
                   />
                    {  !isValid ? (
                <p className="error text-danger">
                  Please select a Campaign.
                </p>
              ) : null}
                </div>
            </div>}

            {scopeType==='Tags' && <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Campaign Tag</label>
                <div className="col-md-9">
                   <CampaignTagsSelector 
                   onTagChange={tagChangeHandler}
                   selectedTags={selectedTag} 
                   multiOption={false} 
                   showLabel={false}
                   />
                    {  !isValidTag ? (
                <p className="error text-danger">
                  Please select a Tag.
                </p>
              ) : null}
                </div>
            </div>}

            <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Email</label>
                <div className="col-md-9">
                    <input type="text" className="form-control" 
                    id="blockValue" 
                    name="blockValue"
                    onChange={formik.handleChange} 
                    value={formik.values.blockValue} 
                    />
                    {formik.touched.blockValue &&
              formik.errors.blockValue ? (
                <span className="error text-danger">
                  {formik.errors.blockValue}
                </span>
              ) : null}
                </div>
                
            </div>
            <div className="form-group row mt-3">
                <label htmlFor="email" className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusActive" 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }}
                        value='1' 
                        checked={+status===1?true:false} />
                        <label className="form-check-label" htmlFor="statusActive">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusInctive" 
                        checked={+status===0?true:false}
                        value='0' 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }} 
                        />
                        <label className="form-check-label" htmlFor="statusInctive">Inactive</label>
                    </div>
                </div>
                
            </div>
            <div id="btnWrapper" className='text-center mt-4'>
                <Button variant='primary' type="submit">Submit</Button>
            </div>
        </form>
    )
}


export default BlockContactFormEmail;