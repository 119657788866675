import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getBusinesses = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData };
    console.log("data block receipient", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_BUSINESSESS, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getSuppliers = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData };
    console.log("suppliers data ", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_SUPPLIERS, data, token);
    console.log('getsuppliers ........', result)
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulters = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console

    data = { page, filterData};
    console.log("defaulters data ", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_DEFAULTERS, data, token);
    console.log('defaulters ........', result)
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getUsers = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console

    data = { page, filterData};
    console.log("users data ", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_USERS, data, token);
    console.log('users ........', result)
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getBlockCreditqDetails = async (blockType, blockValue) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console

    data = { blockType, blockValue};
    console.log("block details data ", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_BLOCKDETAILS, data, token);
    console.log('creditq contact block details ........', result)
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};


const creditqService = {
    getBusinesses,
    getSuppliers,
    getDefaulters,
    getUsers,
    getBlockCreditqDetails
};

export default creditqService;
