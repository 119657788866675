import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css"
import {Row, Col} from "react-bootstrap"
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter"
import { useDispatch, useSelector } from "react-redux";
import Channels from "../../components/Channels/Channels"
import { createCampaignActions } from "../../store/slices/campaigns/createCampaign";
import { getChannels } from "../../store/slices/channels/channels";


const NewCampaign = () => {
    const [selectedChannel, setCelectedChannel] = useState(null)
    const [isValidChannel, setIsValidChannel] = useState(true)
    const { campaign,updateCampData } = useSelector((state) => state.createCampaignSlice);
    const { channels } = useSelector((state) => state.channelsSlice);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const channelSelectHandler = (channel) =>{
        let payload = {channel:channel}
        dispatch(createCampaignActions.setCampaign(payload))
        setCelectedChannel(channel)
    }

    useEffect(()=>{
        console.log('payload reset calling......')
        dispatch(createCampaignActions.reSetCampaign())
        
    },[campaign, dispatch])

    useEffect(()=>{
        if(!channels?.data.length){
            dispatch(getChannels())
        }
    },[channels, dispatch])

    const submitHandler = async () => {
        try{
            if(!selectedChannel){
                setIsValidChannel(false)
                return 
            }
            let payload = {channel:selectedChannel}
            dispatch(createCampaignActions.setCampaign(payload))
            navigate('/campaigns/new/campaign')
        }catch(error){
            console.log('Error:', error)
        }
    }

    return (
        <Layout>
            <Row>
                <Col md={8}>
                    <div className="pageHeader mb-6">
                        <h3 className="smallHeading mb-3">Create Campaign</h3>
                        <h1>Select Channel</h1>
                        <p>You must select a channel you would like to trigger for this campaign.</p>
                    </div>
                    <div className="progressSteps mt-5 mb-5">
                        <Channels 
                        onChannelSection={channelSelectHandler} 
                        setIsValidChannel={setIsValidChannel}
                        />
                    </div>
                    {!isValidChannel && <div className="text-danger pb-3">Please select Channel.</div>}
                    <CampaignFooter 
                        nextStep="Set Compaign Title" 
                        buttonTitle="Set Channel" onNextStepHandler={submitHandler}
                     />
                </Col> 
                <Col md={4} className={Classes.sidebarBackground}>
                    <div className={Classes.sidebarBackground} style={{height:'100VH'}}>
                        <ProgressBar 
                        campaign={campaign} 
                        updateCampData={updateCampData} 
                        allchannels={channels} />
                    </div>
                    
                </Col>
            </Row>
        </Layout>
    )
}

export default NewCampaign;