import {useState, useEffect} from "react"
import Layout from "../../layouts/default";
import { useSelector, useDispatch } from "react-redux";
import { getBlockContacts } from "../../store/slices/blockContacts/blockRecipients";
import ModalDialog from "../../components/UI/ModalDialog";
import BlockContactAddNew from "../../components/BlockContact/AddNew"
import BlockContactListItems from "../../components/BlockContact/ListItems";

const BlockContactsByCampaign = () =>{
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({scopeType:'Campaign'});
  const { blockContacts } = useSelector((state) => state.blockContactsSlice);
  const { refreshBlockContact } = useSelector((state) => state.blockContactCreatetSlice);
  const { refreshBlockContactDel } = useSelector((state) => state.blockContactDeleteSlice);
  const [isShow, setIsShow] = useState(false)
  const [selectedContact, setSelectedContact] = useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
    console.log('getBlockContacts loading.....refreshBlockContact')
    dispatch(getBlockContacts({ page, filterData }));
  },[dispatch, loading, page, filterData,refreshBlockContact, refreshBlockContactDel])

  const modalCloseHandler = () =>{
    setIsShow(false)
  }
  
    return (
        <Layout>
         <BlockContactListItems 
         blockContacts={blockContacts} 
         setFilterData={setFilterData} 
         setIsShow={setIsShow} 
         setSelectedContact={setSelectedContact} 
         setPage={setPage} 
         page={page} 
         setLoading={setLoading} 
         scopeType="Campaign"
         />   
      {isShow && <ModalDialog title="Campaign - Add Contact to Block" isShow={isShow}
      onClose={modalCloseHandler}>
        <BlockContactAddNew 
          scopeType="Campaign" 
          contact={selectedContact} 
          onAddUpdate={modalCloseHandler}
          />
      </ModalDialog>}
        </Layout>
    )
}

export default BlockContactsByCampaign