import { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css";

import { Row, Col } from "react-bootstrap";
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  updateCampaign,
  loadCampaign,
} from "../../store/slices/campaigns/createCampaign";
import { loadCampaignRecurrence } from "../../store/slices/campaigns/createCampaignRecurrence";
import GetPlaceHolders from "../../components/Audience/GetPlaceHolders";
import { getAudience } from "../../store/slices/audiences/audience";
import { getCampaignErrorCount } from "../../store/slices/campaigns/campaignErrors";
import { isValidEmail } from "./../../services/utils";
import { toast } from "react-toastify";
const SetOthers = () => {
  const { campaign, updateCampData } = useSelector(
    (state) => state.createCampaignSlice
  );
  const { audience } = useSelector((state) => state.audienceSlice);
  const [ccData, setCcData] = useState([]);
  const [toData, setToData] = useState([]);
  const [bccData, setBccData] = useState([]);
  const { campaignRecurrence } = useSelector(
    (state) => state.createCampaignRecurrenceSlice
  );
  const [isToDataValid, setIsToDataValid] = useState(true);
  const [options, setOptions] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [toMismatch, setToMismatch] = useState(true);
  const [ccMismatch, setCcMismatch] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let campaignId = params.id;
    if (campaignId) {
      dispatch(loadCampaign(campaignId));
      console.log("loading campain data from step third !!!");
    }
  }, []);

  useEffect(() => {
    if (campaign?.data?.audience?.id) {
      dispatch(getAudience(campaign?.data?.audience?.id));
    }
  }, [campaign, dispatch]);

  const __mismatchVarsHandler = async (to, cc) => {
    try {
      if (audience?.audienceQueryVar) {
        //getQueryPlaceHolders()
        let queryVars = audience?.audienceQueryVar[0].selectKeys
          ? audience?.audienceQueryVar[0].selectKeys.data
          : [];
        console.log(
          "mismatch found queryvars.....okkkkkkkkkkkkkkkk audience ",
          queryVars
        );
        let isToExist = true;
        let isCcExist = true;
        if (queryVars.length) {
          if (to) {
            console.log(
              "change event handler in to.....okkkkkkkkkkkkkkkk To ",
              to
            );
            let campaignTo = to;
            let campaignToArr = campaignTo ? campaignTo.split(",") : [];
            console.log(
              "change event handler in to.....okkkkkkkkkkkkkkkk To campaignToArr ",
              campaignToArr
            );
            isToExist = campaignToArr.every((item) => {
              return queryVars.includes(item);
            });
            setIsValid(isToExist);
            setToMismatch(isToExist);
          }
          if (cc) {
            let campaignCc = cc;
            let campaignCcArr = campaignCc ? campaignCc.split(",") : [];
            isCcExist = campaignCcArr.every((item) => {
              return queryVars.includes(item);
            });
            setCcMismatch(isCcExist);
            setIsValid(isCcExist);
          }
        }
        return isCcExist && isToExist ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  //check if queryvar mismatch
  useEffect(() => {
    if (audience?.audienceQueryVar) {
      __mismatchVarsHandler(campaign?.data.toData, campaign?.data.ccData);
    }
  }, [campaign, audience]);

  //set toData and ccData options
  useEffect(() => {
    if (audience?.audienceQueryVar) {
      //console.log('audience?.audienceQueryVar okkkkk ', audience?.audienceQueryVar)
      let optionArr = [];
      let queryVars = audience?.audienceQueryVar[0]?.selectKeys?.data;
      if (queryVars.length) {
        queryVars.map((item) => {
          optionArr.push({ value: item, label: item });
          return "";
        });
        setOptions(optionArr);
      }
    }
  }, [audience]);

  useEffect(() => {
    let campaignId = params.id;
    if (campaignId) {
      dispatch(loadCampaignRecurrence(campaignId));
    }
  }, [params.id]);

  const __setTodataHendler = () => {
    if (campaign && campaign?.data?.toData !== "" && !toData.length) {
      let dbTodata = campaign?.data?.toData;
      let dbToDataArr = [];
      let selectedOption = [];
      if (dbTodata) {
        dbToDataArr = dbTodata.split(",");
        dbToDataArr.map((item) => {
          selectedOption.push({ value: item, label: item });
          return "";
        });
      }
      setToData(selectedOption);
    }
  };
  const __setBccdataHendler = () => {
    if (campaign && campaign?.data?.bccData !== "" && !bccData.length) {
      let dbBccdata = campaign?.data?.bccData;
      let dbBccDataArr = [];
      if (dbBccdata) {
        dbBccDataArr = dbBccdata.split(",");

        setBccData(dbBccDataArr);
      }
    }
  };

  const __setCcdataHendler = () => {
    if (campaign && campaign?.data?.ccData !== "" && !ccData.length) {
      let dbCcdata = campaign?.data?.ccData;
      let dbCcDataArr = [];
      let selectedOption = [];
      if (dbCcdata) {
        dbCcDataArr = dbCcdata.split(",");
        dbCcDataArr.map((item) => {
          selectedOption.push({ value: item, label: item });
          return "";
        });
      }
      setCcData(selectedOption);
    }
  };
  useEffect(() => {
    __setTodataHendler();
    __setCcdataHendler();
    __setBccdataHendler();
  }, [campaign, audience]);

  const submitHandler = async () => {
    try {
      if (!toData.length) {
        setIsToDataValid(false);
        setIsValid(false);
        return;
      }

      let toDataVal = [];
      toData.map((item) => {
        toDataVal.push(item.value);
        return "";
      });

      let ccDataVal = [];
      ccData.map((item) => {
        ccDataVal.push(item.value);
        return "";
      });

      let isAllOkay = await __mismatchVarsHandler(
        toDataVal.join(","),
        ccDataVal.join(",")
      );
      if (!isAllOkay) {
        console.log("mismatch found.....#okkkkkkkkkkkk", isAllOkay);
        return;
      }

      let payload = {
        ccData: ccDataVal.join(","),
        id: campaign?.data.id,
        toData: toDataVal.join(","),
        isRepeat: campaign?.data.isRepeat,
        audience: campaign?.data?.audience?.id,
        bccData: bccData.join(","),
      };

      if (isValid) {
        dispatch(updateCampaign(payload));
        setTimeout(() => {
          dispatch(getCampaignErrorCount());
        }, 500);
        navigate("/campaigns/edit/template/" + campaign?.data?.id);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const __getMatchedOptions = async (optionsToCompare, optionsWithCompare) => {
    try {
      return optionsToCompare.find((item) => {
        let matched = optionsWithCompare.find((item2) => {
          return item.value === item2.value;
        });
        return matched;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const __toChangeHandle = async (event) => {
    try {
      let isMatched = await __getMatchedOptions(event, ccData);
      console.log("change event handler matched item....", isMatched);
      if (!event.length) {
        setToMismatch(true);
      } else {
        let queryVarsArr = event.map((qvar) => qvar.value);
        let isMismatched = await __mismatchVarsHandler(queryVarsArr.join(","));
        console.log("change event handler mismatch found", isMismatched);
      }

      if (isMatched) {
        return;
      }

      if (!event.length) {
        setIsToDataValid(false);
        setIsValid(false);
      } else {
        setIsToDataValid(true);
        setIsValid(true);
      }
      setToData(event);
    } catch (error) {
      console.log(error);
    }
  };
  const __ccChangeHandle = async (event) => {
    try {
      console.log("_ccChangeHandle.......", event, event.length);
      let isMatched = await __getMatchedOptions(event, toData);
      console.log("_ccChangeHandle matched item....", isMatched);

      if (!event.length) {
        setCcMismatch(true);
      } else {
        let queryVarsArr = event.map((qvar) => qvar.value);
        await __mismatchVarsHandler("", queryVarsArr.join(","));
      }

      if (isMatched) {
        return;
      }

      setCcData(event);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const email = inputValue.trim();
      if (isValidEmail(email)) {
        if (email && !bccData.includes(email)) {
          setBccData([...bccData, email]);
          setInputValue("");
        }
      } else {
        toast.error("Invalid Email");
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setBccData(bccData.filter((email) => email !== emailToRemove));
  };

  return (
    <Layout>
      <Row>
        <Col md={8}>
          <div className="pageHeader mb-6">
            <h3 className="smallHeading mb-3">Create Campaign</h3>
            <h1>Other Details</h1>
          </div>

          <div className="progressSteps mt-3 mb-5">
            <label htmlFor="toData">To</label>
            <Select
              isMulti
              value={toData}
              onChange={__toChangeHandle}
              options={options}
            />

            {!isToDataValid && (
              <p className="text-danger">Please select To placeholder.</p>
            )}
            {!toMismatch && <p className="text-danger">A mismatch found.</p>}
          </div>
          {campaign?.data?.channel.id === 1 && (
            <div className="progressSteps mt-5 mb-5">
              <label htmlFor="ccData">CC</label>
              <Select
                isMulti
                value={ccData}
                onChange={__ccChangeHandle}
                options={options}
              />

              {!ccMismatch && <p className="text-danger">A mismatch found.</p>}
            </div>
          )}
          {campaign?.data?.channel.id === 1 && (
            <div className="progressSteps mt-5 mb-5">
              <label htmlFor="ccData">BCC</label>
              <div className="email-input-container mt-2">
                {bccData.map((email, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#e9ecef",
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "5px",
                      margin: "2px",
                      width: "max-content",
                    }}
                  >
                    {email}
                    <button
                      type="button"
                      onClick={() => removeEmail(email)}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#dc3545",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    >
                      x
                    </button>
                  </div>
                ))}
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  placeholder="Enter email and press Enter"
                  className="form-control"
                />
              </div>

              {!ccMismatch && <p className="text-danger">A mismatch found.</p>}
            </div>
          )}
          <div className="py-3">
            {campaign && <GetPlaceHolders campaign={campaign} />}
          </div>
          <CampaignFooter
            nextStep="Set the Template"
            buttonTitle="Set Others"
            onNextStepHandler={submitHandler}
            showDraft={!campaign?.data?.isRepeat}
          />
        </Col>
        <Col md={4} className={Classes.sidebarBackground}>
          <div
            className={Classes.sidebarBackground}
            style={{ height: "100VH" }}
          >
            <ProgressBar
              campaign={campaign}
              updateCampData={updateCampData}
              campaignRecurrence={campaignRecurrence}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default SetOthers;
