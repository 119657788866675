import { Fragment } from "react";
import {Modal, Button} from "react-bootstrap"

const UIModalDialog = (props) =>{
    const {isShow, setIsShow, title, showBottom, modalType, setIsShowInfo, onClose} = props
    console.log('modal open status.........#################', isShow)
    const handleClose = () => {
        if(modalType==='edit'){
            setIsShow(false)
        }else{
            if(setIsShowInfo){setIsShowInfo(false)}
        }
        if(onClose){
            onClose(false)
        }
        
    }
    
    return (
        <Fragment>
            <Modal show={isShow} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
               {showBottom && <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>}
                
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
export default UIModalDialog;