import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "react-bootstrap";
import SqlQueryResult from "./SqlQueryResult";
import {getAudienceByQuery} from "../../store/slices/campaigns/createCampaign";
import {useSelector, useDispatch} from "react-redux";
import {
  createAudience,
  updateAudience,
} from "../../store/slices/audiences/createAudience";
import CampaignsUsingThisAudience from "../../components/Audience/CampaignsUsingThisAudience";
import {audienceActions} from "../../store/slices/audiences/audience";
import {getCampaignErrorCount} from "../../store/slices/campaigns/campaignErrors";
import {sqlQueryRequiredFieldValidate} from "../../services/utils";

const NewAudience = (props) => {
  const {selectedAudience, isEdit, onNewAudienceSubmit} = props;
  const [validQuery, setValidQuery] = useState(true);
  const [showTest, setShowTest] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const dispatch = useDispatch();
  const previewAudiences = useSelector(
    (state) => state.audienceResultsSlice.audiences
  );
  const {audienceCreated} = useSelector((state) => state.createAudiencesSlice);
  const [queryVarValid, setQueryVarValid] = useState(true);

  const audienceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required field"),
    sqlQuery: Yup.string()
      .required("Query is required field")
      .matches(/^[^*]+$/, "(*) Astric is not allowed"),
  });

  console.log("selected audience...edit", isEdit, selectedAudience);
  const initialName = isEdit ? selectedAudience?.name : "";
  const initialsqlQuery = isEdit ? selectedAudience?.sqlQuery : "";

  const _queryResultTestHandler = async () => {
    try {
      let sqlQuery = formik.values.sqlQuery;
      if (!formik.values.sqlQuery || sqlQuery.indexOf("*") > 0) {
        setValidQuery(false);
        return;
      }

      // let isValid = await sqlQueryRequiredFieldValidate(sqlQuery);
      // if (!isValid) {
      //   setQueryVarValid(false);
      //   return;
      // }

      // console.log("okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk valid........", isValid);

      setQueryVarValid(true);
      setValidQuery(true);
      setShowTest(true);
      let payload = {
        sqlQuery: sqlQuery.replace(";", ""),
        startLimit: 0,
        endLimit: 5,
      };
      dispatch(getAudienceByQuery(payload));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (audienceCreated && isSubmited) {
      if (selectedAudience?.id) {
        dispatch(audienceActions.resetAudience());
      }

      onNewAudienceSubmit();
    }
  }, [audienceCreated, isSubmited, onNewAudienceSubmit]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialName,
      sqlQuery: initialsqlQuery,
    },
    validationSchema: audienceSchema,

    onSubmit: async (values) => {
      let sqlQuery = values.sqlQuery;
      // let isValid = await sqlQueryRequiredFieldValidate(sqlQuery);
      // if (!isValid) {
      //   setQueryVarValid(false);
      //   return;
      // }
      values.sqlQuery = sqlQuery.replaceAll(";", "");
      if (selectedAudience?.id && isEdit) {
        values.id = selectedAudience?.id;
        dispatch(updateAudience(values));
      } else {
        dispatch(createAudience(values));
      }
      setIsSubmited(true);
      //refresh error notification
      setTimeout(() => {
        dispatch(getCampaignErrorCount());
      }, 500);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-2 col-form-label">
          Name
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <span className="error text-danger">{formik.errors.name}</span>
          ) : null}
        </div>
      </div>
      <div className="row mt-4">
        <label htmlFor="sqlQuery" className="form-label">
          Query
        </label>
        <div className="">
          <textarea
            className="form-control"
            rows="7"
            name="sqlQuery"
            id="sqlQuery"
            onChange={formik.handleChange}
            value={formik.values.sqlQuery}
          ></textarea>
        </div>
        <div className="mt-2 text-end">
          <Button variant="secondary" onClick={_queryResultTestHandler}>
            Test Query
          </Button>
          {queryVarValid}
        </div>
        {!validQuery && (
          <p className="text-danger">Please enter a valid query.</p>
        )}
        {!queryVarValid && (
          <p className="text-danger">Required query vars are missing.</p>
        )}
        {formik.touched.sqlQuery && formik.errors.sqlQuery ? (
          <span className="error text-danger">{formik.errors.sqlQuery}</span>
        ) : null}
      </div>

      {isEdit && (
        <CampaignsUsingThisAudience audienceId={selectedAudience?.value} />
      )}

      <div className="queryResults">
        {previewAudiences && showTest && validQuery && (
          <SqlQueryResult queryResults={previewAudiences} />
        )}
      </div>
      <div id="btnWrapper" className="text-center mt-4">
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default NewAudience;
